<template>
  <div class="dialog-overlay" v-if="modalVisible">
    <div
      class="dialog bs_100"
      :style="{
        'background-image': 'url(' + imgInfo.bg_img + ')',
        width: '66rem',
        height: '37rem',
      }"
    >
      <img
        :class="`dialog-close`"
        src="@/assets/pc/popup/popup_closebutton.png"
        alt=""
        @click="close"
      />
      <img class="dialog-title" :src="imgInfo.title_img" alt="" />
      <div class="dialog-content">
        <el-table
          :data="tableData"
          class="mainTable"
          border
          height="calc(100% - 10rem)"
          style="width: 100%,height: 50%;"
          v-loading="queryLoading"
        >
          <el-table-column
            prop="id"
            align="center"
            :label="$t('prize.$4')"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            :label="$t('prize.$5')"
            width="180"
          >
            <template slot-scope="{ row }">
              {{ row.name || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="create_at"
            align="center"
            :label="$t('prize.$6')"
          >
            <template slot-scope="{ row }">
              {{ row.create_at ? formatTimestampToJST(row.create_at) : "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cdk_code"
            align="center"
            :label="$t('prize.$7')"
          >
            <template slot-scope="{ row }">
              <span ref="n_text" class="n_text">{{ row.cdk_code }}</span>
              <span class="n_link" @click="copyToClipboard(row.cdk_code)">{{
                $t("prize.$3")
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <span class="tip">{{ $t("prize.$8") }}</span>
        <span class="tip">{{ $t("prize.$9") }}</span>
        <div class="btn">
          <img
            v-for="(item, index) in imgInfo.btn_img_list"
            :key="index"
            :src="hover_index === index ? item.act : item.def"
            alt=""
            @mouseenter="hover_index = index"
            @mouseleave="hover_index = -1"
            @click="handelConfirm(index)"
          />
        </div>
      </div>
    </div>
    <div ref="copyArea"></div>
  </div>
</template>
      
      <script>
import { mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import ClipboardJS from "clipboard";
import { reqLotteryWinning } from "@/settings/api.js";
export default {
  props: {
    visible: false,
  },
  data() {
    return {
      modalVisible: true,
      tableData: [],
      hover_index: -1,
      queryLoading: false,
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.modalVisible = val;
        if (val) {
          this.getLotteryList();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["msg", "region", "swiperEnable", "region_type", "user_info"]),

    imgInfo() {
      const imgObj = {
        TW: {
          title_img: require("@/assets/pc/popup/popup_title5.png"),
          bg_img: require("@/assets/pc/popup/popup_bg6.png"),
          btn_img_list: [
            {
              def: require("@/assets/pc/popup/popup_button_pre_ok_2.png"),
              act: require("@/assets/pc/popup/popup_button_pre_ok.png"),
            },
            {
              def: require("@/assets/pc/popup/popup_button2_unselected.png"),
              act: require("@/assets/pc/popup/popup_button2_normal.png"),
            },
            {
              def: require("@/assets/pc/popup/popup_button3_unselected.png"),
              act: require("@/assets/pc/popup/popup_button3_normal.png"),
            },
          ],
        },
      };
      return imgObj["TW"];
    },
    transformContent() {
      return (text) => {
        return text.split("\n");
      };
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapMutations(["openNewMsg", "setSwiperStatus", "setSwiperActiveIndex"]),
    open() {
      this.modalVisible = true;
    },
    close() {
      this.modalVisible = false;
      this.$emit("closeModal", false);
      this.setSwiperStatus(true);
    },

    getLotteryList() {
      if (!this.user_info.identity_id) {
        return;
      }
      this.queryLoading = true;
      reqLotteryWinning({
        app_id: +process.env.VUE_APP_PROJECT_ID,
        identity_id: this.user_info.identity_id,
        region_type: this.region_type,
      })
        .then((res) => {
          if (res.code === 20000) {
            let arr = res.data?.list || [];
            arr.map((item, index) => {
              item.id = index + 1;
              return item;
            });
            this.tableData = arr;
          } else {
            this.openNewMsg({
              title: this.$t("msg.title"),
              body: this.$t(`errMsg.$${res.code}`),
              autoClose: true,
              time: 1000,
            });
          }
        })
        .finally(() => {
          this.queryLoading = false;
        });
    },
    formatTimestampToJST(timestamp) {
      // 创建一个Date对象并加上日本时区偏差
      var japanTimeOffset = 8 * 60; // 日本时区UTC+9的分钟数
      var date = new Date(timestamp * 1000);
      var utc = date.getTime() + date.getTimezoneOffset() * 60000; // 转换为UTC时间
      var japanTime = new Date(utc + (3600000 * japanTimeOffset) / 60);

      // 获取各个部分并格式化
      var year = japanTime.getFullYear();
      var month = japanTime.getMonth() + 1; // getMonth() 返回的月份是从0开始的
      var day = japanTime.getDate();
      var hours = japanTime.getHours();
      var minutes = japanTime.getMinutes();
      var seconds = japanTime.getSeconds();

      // 补零函数
      function padZero(n) {
        return n < 10 ? "0" + n : n;
      }

      // 拼接成最终字符串
      var formatted =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        padZero(hours) +
        ":" +
        padZero(minutes) +
        ":" +
        padZero(seconds);

      return formatted;
    },

    copyToClipboard(v) {
      // 使用一个新的 Clipboard 实例来复制文本
      const clipboard = new ClipboardJS(this.$refs.copyArea, {
        text: () => v,
      });

      clipboard.on("success", (e) => {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t("msg.copy_success"),
          autoClose: true,
          time: 1000,
        });
        e.clearSelection(); // 清除高亮选择
        clipboard.destroy(); // 复制成功后释放资源
      });

      clipboard.on("error", (e) => {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t("msg.copy_fail"),
          autoClose: true,
          time: 1000,
        });
        clipboard.destroy(); // 遇到错误后释放资源
      });

      // 触发按钮的点击事件以启动复制操作
      this.$refs.copyArea.click();
    },
    handelConfirm(ind) {
      switch (ind) {
        case 0:
          this.close();
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          this.close();
          break;
      }
    },
  },
};
</script>
  <style>
.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: #566567 !important;
}
</style>
  <style lang="scss" scoped>
@keyframes open_mask {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
::v-deep .el-table {
  font-size: 1.3rem;

  th.el-table__cell {
    background-color: #566567;
    color: #fff;
  }
  tr {
    background: rgba(26, 37, 40, 1);
    color: #fff;

    &:hover {
      background: rgba(26, 37, 40, 0.5) !important;
    }
    td > .cell {
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }

  /* 针对基于WebKit内核的浏览器（如Chrome、Edge、Safari） */
  .el-table__body-wrapper::-webkit-scrollbar-track {
    display: none;
    background-color: #566567; /* 将这里替换为你选择的背景色 */
  }
  .el-table__body-wrapper {
    background: rgba(26, 37, 40, 1);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      //   background-color: transparent; /* 默认透明 */
    }
    &::-webkit-scrollbar-track {
      background-color: transparent; /* 默认透明 */
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #fff !important;
      }
    }
  }
}
/* 添加对话框的基本样式 */
.dialog-overlay {
  /* 样式省略，覆盖整个屏幕的半透明背景 */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.58);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  animation: open_mask 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.dialog {
  /* 对话框样式 */
  width: 37%;
  height: 72.88%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.56rem;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  &-close {
    width: 2.56rem;
    height: 2.56rem;
    position: absolute;
    top: -1.45rem;
    right: -3.56rem;
    cursor: pointer;
  }

  &-title {
    width: auto;
    height: 4.5rem;
    margin-bottom: 1rem;
    margin-top: -1rem;
  }
  &-content {
    width: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .n_text {
      display: inline-block;
      height: 1.3rem;
      line-height: 100%;
      width: calc(100% - 8rem);
      max-width: calc(100% - 8rem);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
    }
    .n_link {
      display: inline-block;
      height: 1.3rem;
      font-size: 1.3rem;
      line-height: 100%;

      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffeaac;
      text-decoration: underline;
      margin-left: 1.5rem;
      cursor: pointer;
      padding-bottom: 0.6rem;
    }
    .tip {
      width: 100%;
      height: 0.88rem;
      line-height: 0.88rem;
      text-align: left;
      font-size: 0.87rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #8bacb0;
      margin-top: 0.75rem;
      &:last-child {
        margin-top: 0.45rem;
      }
    }
    .btn {
      margin-top: 2rem;
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: 100%;
        margin: 0 1.5rem;
        cursor: pointer;
      }
    }
  }

  &-btn {
    width: 12rem;
    height: 3.88rem;
    cursor: pointer;
    margin-top: 2rem;
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>