<template>
  <div class="register">
    <div class="content">
      <div
        class="content_center"
        :style="{
          'background-image': 'url(' + imgInfo.content_bg + ')',
          'background-size': '100% 100%',
          'background-repeat': 'no-repeat',
          'background-position': 'center top',
        }"
      >
        <img class="title" :src="imgInfo.title_bg" alt="" />
        <i class="el-icon-close" @click="setRegisterVisibleStatus(false)"></i>

        <img
          class="q_mark"
          src="@/assets/pc/register/bp_title_punct.png"
          alt=""
          @click="showTipsModal"
        />
        <div class="area">
          <span class="area_tip">{{ $t("register.title1") }}</span>
          <div class="area_phone">
            <!-- <el-select
              v-model="area_code"
              placeholder="请选择"
              class="area_phone_select"
              popper-class="area_phone_select_dropdown_m"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <div
              class="area_phone_select"
              @click.stop="showAreaOptionsModal = !showAreaOptionsModal"
            >
              <span class="area_code">{{ countAreaCodeLabel }}</span>
              <i
                class="el-icon-caret-bottom"
                :class="{
                  arrow_bt: !showAreaOptionsModal,
                  arrow_top: showAreaOptionsModal,
                }"
              ></i>
              <transition name="el-zoom-in-top">
                <div class="area_code_options" v-show="showAreaOptionsModal">
                  <div class="line"></div>
                  <div
                    class="item"
                    :class="{ act_item: item.value === area_code }"
                    v-for="item in options"
                    :key="item.value"
                    @click.stop="choiceAreaCode(item)"
                  >
                    <span>
                      {{ item.label }}
                    </span>
                  </div>
                </div>
              </transition>
            </div>
            <el-input
              class="area_phone_input"
              v-model="email"
              :placeholder="$t('register.tip')"
              :disabled="disabled"
            ></el-input>
          </div>
          <div class="area_check">
            <el-checkbox v-model="choice">
              <span>{{ $t("register.agree") }}</span>
            </el-checkbox>
          </div>
          <div class="area_agree">
            <span @click="viewAgreement(1)">{{ $t("register.agree1") }}</span>
            <span @click="viewAgreement(2)"> {{ $t("register.agree2") }}</span>
          </div>

          <div
            class="area_btn"
            :style="{
              'background-image':
                'url(' +
                (isViewPrizeStatus ? imgInfo.btn_done_img : imgInfo.btn_img) +
                ')',
            }"
            @click="handelRegister"
          ></div>
          <div class="area_award">
            <div
              class="item bs_cover"
              v-for="(item, index) in imgInfo.award_list"
              :key="index"
              :style="{
                'background-image': 'url(' + item + ')',
              }"
            ></div>
          </div>
          <div class="area_download">
            <img
              v-for="(item, index) in imgInfo.link_list"
              :key="index"
              :src="item.img"
              alt=""
              @click="openLink(item.name)"
            />
          </div>
          <span class="area_tips">
            {{ $t("register.tip4") }}
          </span>
        </div>
      </div>
    </div>
    <registerSuccessModal
      :visible="registerSuccessInfo.visible"
      :type="registerSuccessInfo.type"
      :cdk_code="registerSuccessInfo.cdk_code"
      @closeModal="closeRegisTipsModal"
      @openLink="openLink"
    />
  </div>
</template>
  
  <script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
import { mapMutations, mapState } from "vuex";
import registerSuccessModal from "./components/registerSuccessModal.vue";
import { reqReservation, reqStoreReservation } from "@/settings/api.js";

export default {
  name: "component_name",
  components: {
    registerSuccessModal,
  },
  data() {
    return {
      activePeopleNum: 0,
      area_code: "",
      email: "",
      showAreaOptionsModal: false,
      showDesModal: false,
      choice: false,
      disabled: false,
      registerSuccessInfo: {
        visible: false,
        cdk_code: "",
        type: 1, //1  事前登录   2商店注册
      },
      options: [
        {
          value: "886",
          label: "臺灣+886",
        },
        {
          value: "853",
          label: "澳門+853",
        },
        {
          value: "852",
          label: "香港+852",
        },
      ],
    };
  },
  created() {},
  computed: {
    ...mapState(["msg", "title", "region", "region_type", "user_info"]),
    countAreaCodeLabel() {
      return this.area_code
        ? this.options.find((item) => {
            return item.value === this.area_code;
          })?.label
        : "请选择";
    },
    imgInfo() {
      const imgObj = {
        TW: {
          content_bg: require("@/assets/mobile/register/bp.png"),
          title_bg: require("@/assets/mobile/register/title.png"),
          btn_img: require("@/assets/mobile/register/anniupre.png"),
          btn_done_img: require("@/assets/mobile/register/anniu_normal.png"),
          award_list: [
            require("@/assets/mobile/register/gif1.png"),
            require("@/assets/mobile/register/gif3.png"),
            require("@/assets/mobile/register/gif2.png"),
          ],
          link_list: [
            {
              name: "google",
              img: require("@/assets/mobile/register/bp_download_googleplay.png"),
            },
            {
              name: "ios",
              img: require("@/assets/mobile/register/bp_download_appstore.png"),
            },
          ],
        },
      };
      return imgObj[this.region];
    },
    // 是否是查看奖励的状态
    isViewPrizeStatus() {
      let { email, identity_id, area_code } = this.user_info;
      if (identity_id && this.email === email && this.area_code === area_code) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", (e) => {
      if (
        !e.target?.className ||
        e.target?.className.indexOf("area_phone_select") == -1
      ) {
        this.showAreaOptionsModal = false;
      }
    });
  },
  watch: {
    user_info: {
      handler(val) {
        if (val.email && val.area_code) {
          this.email = val.email;
          this.area_code = val.area_code;
          this.choice = true;
          // this.disabled = true;
        } else {
          this.email = "";
          this.choice = false;
          // this.disabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["openNewMsg", "setUserInfo", "refreshCanLotteryNum"]),
    closeRegisTipsModal() {
      this.registerSuccessInfo = {
        visible: false,
        cdk_code: "",
        type: 1, //1  事前登录   2商店注册
      };
    },
    choiceAreaCode(item) {
      let { value } = item;
      if (this.area_code === value) {
        return;
      }
      this.area_code = value;
      this.showAreaOptionsModal = false;
    },
    handelRegister: debounce(function () {
      const cur_date = new Date().getTime();
      const end_date = new Date("2024-07-18 24:00:00").getTime();

      if (cur_date >= end_date) {
        this.$tipMobileModal({
          type: "type6",
          onClose: () => {},
        });
        return;
      }
      const _this = this;
      const title = _this.$t("msg.title");
      if (!this.area_code) {
        this.openNewMsg({
          title,
          body: "請先選擇區號",
          autoClose: true,
          time: 1000,
        });
        return;
      }
      let reg;
      if (this.area_code === "886") {
        // 台湾手机号码：格式一般为 09xx-xxxxxx。共有 10 位数字，且以 09 开头。
        reg = /^09\d{8}$/;
      } else if (this.area_code === "853") {
        // 澳门手机号码：格式一般为 xxxx-xxxx。共有 8 位数字，通常以 6 开头。
        reg = /^6\d{7}$/;
      } else if (this.area_code === "852") {
        // 通常由 8 位数字组成，而且多数情况下以数字 5, 6, 或 9 开头
        reg = /^(5|6|9)\d{7}$/;
      }
      if (!this.choice) {
        this.openNewMsg({
          title,
          body: this.$t("register.agree"),
          autoClose: true,
          time: 1000,
        });
      } else {
        if (!reg.test(this.email)) {
          this.openNewMsg({
            title,
            body: "請輸入正確的電話號碼",
            autoClose: true,
            time: 1000,
          });
        } else {
          if (this.isViewPrizeStatus) {
            // this.registerSuccessInfo = {
            //   visible: true,
            //   cdk_code: localStorage.getItem("email_cdk_code"),
            //   type: 1,
            // };
            return;
          }

          reqReservation({
            app_id: +process.env.VUE_APP_PROJECT_ID,
            device: this.user_info?.device,
            // device: Math.random().toString(),
            ip: this.user_info?.ip,
            email: `${this.area_code}${this.email}`,
            invited_id: this.user_info?.invite_id || undefined,
            region_type: this.region_type,
          }).then((res) => {
            if (res.code === 20000 || res.code === 20001) {
              let { can_lottery_num, cdk_code, identity_id } = res.data;
              _this.setUserInfo({ can_lottery_num });
              _this.setUserInfo({ identity_id });
              _this.setUserInfo({ email: _this.email });
              _this.setUserInfo({ area_code: _this.area_code });
              this.refreshCanLotteryNum();
              trackClick(
                {
                  behavior_tag: "Submit Email",
                },
                true
              );
              // if (cdk_code) {
              //   localStorage.setItem("email_cdk_code", cdk_code);
              //   this.registerSuccessInfo = {
              //     visible: true,
              //     cdk_code,
              //     type: 1,
              //   };
              // }
            } else {
              this.openNewMsg({
                title: this.$t("msg.title"),
                body: this.$t(`errMsg.$${res.code}`),
                autoClose: true,
                time: 1000,
              });
            }
          });
        }
      }
    }, 600),
    jumpLink(way) {
      let urlObj = {
        ios: {
          link: "https://apps.apple.com/app/id6450451551",
          track_name: "Click Ios store",
        },
        google: {
          link: "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.tw",
          track_name: "Click Google store",
        },
        twitter: {
          link: "https://twitter.com/NoDgameJP",
          track_name: "Click Twitter",
        },
        // wait
        youtube: {
          link: "https://www.youtube.com/@NoDgames",
          track_name: "Click Youtube",
        },
        // wait
        line: {
          link: "https://lin.ee/J9fiO9G",
          track_name: "Click Line",
        },
      };
      let url = urlObj[way].link;
      window.open(url, "_blank");
      // window.open(url, "_blank");
      trackClick({
        behavior_tag: urlObj[way].track_name,
      });
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Download Ios package",
        });
        window.open("https://apps.apple.com/app/id6450451551", "_blank");
      } else {
        trackClick({
          behavior_tag: "Download Android package",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.tw",
          "_blank"
        );
      }
    },
    showTipsModal() {
      this.$tipMobileModal({
        type: "type4",
        onClose: () => {},
      });
    },
    viewAgreement(type) {
      switch (type) {
        case 1:
          window.open(
            "https://static-sites.allstarunion.com/service/general_protocol_tw.html",
            "_blank"
          );
          break;
        case 2:
          window.open(
            "https://static-sites.allstarunion.com/service/general_privacy_tw.html",
            "_blank"
          );
          break;
        default:
          break;
      }
    },
  },

  destroyed() {},
};
</script>
  
  <style lang="scss" scoped>
.register {
  width: 100%;
  height: 75rem;
  margin-top: 8rem;
  position: relative;
  // overflow: hidden;
  // position: absolute;
  // top: 0;

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 0%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &_center {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      .title {
        position: absolute;
        top: -2.5rem;
        left: 4rem;
        width: 20.44rem;
      }
      .q_mark {
        position: absolute;
        top: -0.6rem;
        left: 23rem;
        height: 2rem;
        cursor: pointer;
      }

      .area {
        width: calc(100% - 15rem);
        height: auto;
        position: absolute;
        left: 50%;
        top: 11.7rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transform: translate(-48%, 0);

        &_tip {
          font-size: 3.65rem;
          height: 3.65rem;
          line-height: 3.65rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            #decc9e 99.365234375%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-top: 0.7rem;
        }
        &_phone {
          width: 100%;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 4.12rem;

          &_select {
            width: 15.4rem;
            height: 100%;
            border: 1px solid;
            border-image: linear-gradient(0deg, #d3c583, #d9d09d) 10 10;
            background: linear-gradient(0deg, #5d858f 0%, #274d54 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            .area_code {
              text-align: center;

              font-size: 1.4rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
            .el-icon-caret-bottom {
              color: #fff;
              margin-left: 1rem;
            }
            .arrow_bt {
              transform: rotate(0);
              transition: all 0.3s ease-in-out;
            }
            .arrow_top {
              transform: rotate(180deg);
              transition: all 0.3s ease-in-out;
            }
            .area_code_options {
              width: calc(100% + 2px);
              height: auto;
              position: absolute;
              z-index: 99;
              left: -1px;
              top: calc(4rem - 2px);
              border: 1px solid;
              box-sizing: border-box;
              border-image: linear-gradient(0deg, #d3c583, #d9d09d) 10 10;
              background: linear-gradient(0deg, #274d54 0%, #5d858f 100%);
              border-top: none;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .line {
                width: 90%;
                height: 0.5px;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: 5%;
              }
              .item {
                width: 100%;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                &:hover {
                  background-color: RGBA(255, 255, 255, 0.2);
                  cursor: pointer;
                }
                span {
                  font-size: 1.4rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: center;
                }
              }
              .act_item {
                background-color: RGBA(255, 255, 255, 0.2);
              }
            }
          }
          &_input {
            width: 25.72rem;
            margin-left: 2.3rem;
            ::v-deep {
              .el-input__inner {
                width: 25.72rem;
                height: 4rem;
                line-height: 4rem;
                border: 1px solid;
                border-image: linear-gradient(0deg, #d3c583, #d9d09d) 10 10;
                background: linear-gradient(0deg, #5d858f 0%, #274d54 100%);
                font-size: 1.4rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
                &::placeholder {
                  width: 100%;
                  text-align: center;
                  font-size: 1.36rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
            }
          }
        }

        &_check {
          width: 100%;
          height: 1.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2.65rem;

          ::v-deep {
            .el-checkbox {
              display: flex;
              align-items: center;
            }
            .el-checkbox__inner {
              width: 1.5rem;
              height: 1.5rem;
              border: 1px solid #d3c583;
              background-color: transparent;
              &::after {
                height: 5px !important;
                left: 2px !important;
                top: 0px !important;
              }
            }
            .el-checkbox__label {
              font-size: 1.4rem;
              font-family: Source Han Sans CN;
              font-weight: 300;
              color: #ffffff;
              padding: 0;
              padding-left: 0.65rem;
              height: 2rem;
              line-height: 2rem;
              font-weight: normal;
              span {
                height: 1.4rem;
                line-height: 1.4rem;
              }
            }
          }
        }
        &_agree {
          width: 100%;
          height: 1.4rem;
          line-height: 1.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.8rem;
          span {
            font-size: 1.4rem;
            height: 1.4rem;
            ine-height: 1.4rem;
            font-family: Source Han Sans CN;
            color: #ffffff;
            text-decoration: underline;
            &:last-child {
              margin-left: 1.85rem;
            }
          }
        }
        &_btn {
          width: 16.8rem;
          height: 5.44rem;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2.4rem;
          &:hover {
            transform: scale(1.05);
            cursor: pointer;
          }
        }
        &_award {
          width: 100%;
          height: 13.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.5rem;
          .item {
            width: 15.5rem;
            height: 100%;
          }
        }
        &_download {
          width: 40rem;
          height: 5.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2.25rem;
          img {
            height: 100%;
            &:hover {
              cursor: pointer;
              transform: scale(1.05);
            }
            &:first-child {
              width: 20.2rem;
            }
            &:last-child {
              width: 16.5rem;
            }
          }
        }
        &_tips {
          width: auto;
          height: 1.35rem;
          margin-top: 2.1rem;
          font-family: Source Han Sans CN;
          font-weight: 300;
          font-size: 1.36rem;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
</style>
  