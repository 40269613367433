<template>
  <div class="dialog-overlay" v-if="visible">
    <div
      class="dialog bs_100"
      :style="{
        'background-image': 'url(' + imgInfo[type].bg_img + ')',
        width: imgInfo[type].width,
        height: imgInfo[type].height,
        marginTop: imgInfo[type].top || '',
      }"
    >
      <img
        :class="`dialog-close_${imgInfo[type].closeType}`"
        src="@/assets/pc/popup/popup_closebutton.png"
        alt=""
        @click="close"
      />
      <img
        class="dialog-title"
        :style="{
          height: imgInfo[type].title_height,
          marginBottom: imgInfo[type].title_mb || '',
          marginTop: imgInfo[type].title_mt || '',
        }"
        :src="imgInfo[type].title_img"
        alt=""
      />
      <div class="dialog-content" v-if="imgInfo[type].content">
        <p
          v-for="(item, index) in transformContent(imgInfo[type].content)"
          :key="index"
          :style="{
            textAlign: imgInfo[type].textAlign,
          }"
          v-html="item"
        ></p>
      </div>
      <div class="dialog-reward" v-if="type === 'type5'">
        <div
          class="reward_bg bs_contain"
          :style="{
            'background-image':
              'url(' + require('@/assets/pc/popup/popup_bg12_texiao.png') + ')',
          }"
        >
          <div
            class="bs_contain reward_img"
            :style="{
              'background-image': 'url(' + reward.prize_show_img + ')',
            }"
          ></div>
          <span>{{ reward.name }}</span>
        </div>
      </div>

      <img
        v-if="imgInfo[type].btn_img"
        class="dialog-btn"
        :src="imgInfo[type].btn_img"
        alt=""
        @click="handelConfirm"
        :style="{ marginTop: type === 'type5' ? '-1.5rem' : '' }"
      />
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    type: {
      type: String,
      default: "type1",
    },
    onClose: Function,
    onConfirm: Function,
    reward: {
      type: Object,
      default: () => {},
    },
    // 剩余次数
    residueDegree: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    // ...mapState(["msg", "region", "swiperEnable"]),

    imgInfo() {
      const imgObj = {
        TW: {
          // 次数不足
          type1: {
            title_img: require("@/assets/pc/popup/popup_title4.png"),
            title_height: "3.5rem",
            bg_img: require("@/assets/pc/popup/popup_bg4.png"),
            width: "35rem",
            height: "19rem",
            textAlign: "center",
            content: `您的抽獎次數不足，請完成任務獲得抽獎次數後再操作。`,
            btn_img: require("@/assets/pc/popup//popup_button_normal_ok.png"),
            closeType: 1,
          },
          // 预约进度说明
          type2: {
            title_img: require("@/assets/pc/popup/popup_title8.png"),
            title_height: "6rem",
            title_mb: "0",
            title_mt: "-1.5rem",
            bg_img: require("@/assets/pc/popup/popup_bg4.png"),
            width: "54rem",
            height: "22rem",
            content: `活動時間: 
                      2024年6月3日至遊戲上線日
              <div style='height:20px'>
              </div>
              活動規則：
              預約總人數達到目標即可獲得該階段的獎勵，遊戲上線後在遊戲中【活動中心】內領取獎勵（遊戲領主等級達到6級可領取）。`,
            closeType: 2,
          },
          // 活动规则说明
          type3: {
            title_img: require("@/assets/pc/popup/popup_title2.png"),
            bg_img: require("@/assets/pc/popup/popup_bg3.png"),
            width: "45rem",
            height: "50rem",
            top: "3rem",
            textAlign: "left",
            content: `活動時間:
            2024年6月3日至遊戲上線日
            抽獎規則：
1.請（在本頁面）完成電話號碼預約，才可參與抽獎活動。
2.抽獎次數的獲取方法:
  A.填寫電話號碼完成預約可獲得1次抽獎次數；
  B.前往（任意）媒體點選關注官方賬號可獲得1次抽獎次數；
  C.透過專屬連結邀請好友，且該好友成功完成電話號碼預約後，您可獲得1次抽獎次數，最多可成功邀請3位好友、獲得3次抽獎次數。
3.每位玩家最多可以獲得5次抽獎機會。
                      <div style='height:20px'>
                      </div>
                      禮品發放:
1.每次抽中獎品可獲得對應獎品的兌換碼，可在【中獎查詢】內檢視獎品兌換碼。
2.獲得遊戲內道具獎勵的玩家，遊戲上線（首次下載併成功註冊）後，在遊戲官網憑藉（【兌換碼】和【使用者ID】領取獎勵（完成新手引導後，點選頭像可獲取【使用者ID】）），獎勵會發放至對應遊戲賬號，（遊戲內道具獎勵的領取截止時間為2024年12月31日，若逾期未領取則視為自動放棄獎勵）。
3.獲得實體獎勵的玩家，需要在遊戲正式上線後（10個工作日）內，憑藉【兌換碼】、【預約電話號碼】在遊戲內聯絡官方客服，若逾期未聯絡官方則視為自動放棄獎勵，（請保管好您的【兌換碼】和【預約電話號碼】資訊，若因資訊洩露造成實體獎勵被他人領取，官方不會做其他補償）。
4.本次抽獎活動的實體獎勵，無法折換為現金或等值商品。
5.本活動獎品寄送範圍限香港、澳門、臺灣地區的玩家，請根據自己所在地區參加相應活動。
6.如發現玩家使用外掛、指令碼、作弊等不正當方式參與本次活動，官方團隊將取消玩家參與資格及獎勵。）
7.《野獸領主》官方保留對活動的修改權及最終解釋權。

                      <div style='height:20px'>
                      </div>
                      邀請好友規則：
1.通過專屬連結邀請好友，該好友通過邀請連結完成電話號碼預約後即為邀請成功。
2.邀請好友總人數達到1、3、5人時，可獲得對應的邀請進度獎勵，可在【中獎查詢】內查看對應獎勵的兌換碼，遊戲上線（首次下載並成功注册）後，在遊戲官網憑藉（【兌換碼】和【用戶ID】領取獎勵（完成新手引導後，點擊頭像可獲取【用戶ID】）），獎勵會發放至對應遊戲帳號（遊戲內道具獎勵的領取截止時間為2024年12月31日，若逾期未領取則視為自動放弃獎勵）。`,
            closeType: 1,
          },
          // 邮件预约说明
          type4: {
            title_img: require("@/assets/pc/popup/popup_title7.png"),
            title_height: "3.5rem",
            title_mb: "0.5rem",
            title_mt: "-1rem",
            bg_img: require("@/assets/pc/popup/popup_bg6.png"),
            width: "54rem",
            height: "25rem",
            top: "3rem",
            textAlign: "left",
            content: `活動時間: 
                      2024年6月3日至遊戲上線日
                        <div style='height:20px'>
                        </div>
                        活動規則：
                        1、填寫電話號碼完成預約即可獲得電話號碼預約獎勵，遊戲上線後在遊戲中【活動中心】內領取獎勵（遊戲領主等級達到6級可領取）。
                        2、每個電話號碼號只能參與1次預約活動，請確保填寫正確的電話號碼，此電話號碼將作為抽獎活動獎勵領取的重要憑證。參加本次活動即代表同意個人資料收集使用及接收獎勵簡訊，我們將嚴格保護您的隱私權，不會向第三方披露您的個人資訊。`,
            closeType: 2,
          },
          // 中奖提示
          type5: {
            title_img: require("@/assets/pc/popup/popup_title10.png"),
            title_height: "5.5rem",
            title_mb: "0.5rem",
            title_mt: "-2rem",
            bg_img: require("@/assets/pc/popup/popup_bg6.png"),
            width: "44rem",
            height: "28rem",
            top: "3rem",
            textAlign: "left",
            btn_img: this.residueDegree
              ? require("@/assets/pc/popup/popup_button6_normal.png")
              : require("@/assets/pc/popup/popup_button7_normal.png"),
            closeType: 2,
          },
          // 活动结束
          type6: {
            title_img: require("@/assets/pc/popup/popup_title4.png"),
            title_height: "3.5rem",
            bg_img: require("@/assets/pc/popup/popup_bg4.png"),
            width: "35rem",
            height: "19rem",
            textAlign: "center",
            content: `很抱歉，活動已結束！`,
            btn_img: require("@/assets/pc/popup//popup_button_normal_ok.png"),
            closeType: 1,
          },
        },
      };
      return imgObj["TW"];
    },
    transformContent() {
      return (text) => {
        return text.split("\n");
      };
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      if (this.onClose) {
        this.onClose();
      }
    },
    handelConfirm() {
      switch (this.type) {
        case "type1":
          this.close();
          break;
        case "type5":
          if (this.residueDegree) {
            this.onConfirm();
          } else {
            this.close();
          }
          break;
        case "type6":
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@keyframes open_mask {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/* 添加对话框的基本样式 */
.dialog-overlay {
  /* 样式省略，覆盖整个屏幕的半透明背景 */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.58);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  animation: open_mask 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.dialog {
  /* 对话框样式 */
  width: 37%;
  height: 72.88%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.56rem;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  &-close_1 {
    width: 2.56rem;
    height: 2.56rem;
    position: absolute;
    top: -1.45rem;
    right: -3.56rem;
    cursor: pointer;
  }
  &-close_2 {
    width: 2.56rem;
    height: 2.56rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
  }
  &-title {
    width: auto;
    height: 3.5rem;
    margin-bottom: 2rem;
  }
  p {
    margin: 0;
    margin: 0.2rem 0;
  }
  &-content {
    width: 100%;
    height: auto;
    max-height: calc(100% - 0rem);
    overflow-y: auto;
    overflow-x: hidden;
    /* 自定义滚动条的宽度和颜色 */
    &::-webkit-scrollbar {
      width: 8px; /* 滚动条宽度 */
      height: 8px; /* 水平滚动条高度 */
    }

    /* 滚动条轨道样式 */
    &::-webkit-scrollbar-track {
      background: transparent; /* 轨道颜色 */
      border-radius: 6px; /* 轨道圆角 */
    }

    /* 滚动条滑块样式 */
    &::-webkit-scrollbar-thumb {
      background: #fff; /* 滑块悬停颜色 */

      border-radius: 6px; /* 滑块圆角 */
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #888; /* 滑块悬停颜色 */
    }
    p {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  &-reward {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .reward_bg {
      width: 25rem;
      height: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: -3rem;
      .reward_img {
        width: 8rem;
        height: 8rem;
        margin-top: 3rem;
      }
      span {
        font-size: 0.9rem;
        color: #fff;
        margin-top: 1rem;
      }
    }
  }
  &-btn {
    width: 12rem;
    height: 3.88rem;
    cursor: pointer;
    margin-top: 2rem;
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>