<template>
  <div
    class="header"
    :style="{
      backgroundColor: !mobile
        ? registerVisible || curActiveSlideIndex
          ? 'RGBA(10, 16, 11, 1)'
          : ''
        : '',
      'background-image':
        'url(' + (mobile ? imgInfo.header_bg : imgInfo.header_bg) + ')',
      'background-size': '100% 140%',
      'background-repeat': 'no-repeat',
      'background-position': 'center top',
    }"
  >
    <img class="header_icon" :src="imgInfo.icon_img" alt="" />
    <div class="header_act">
      <div
        class="link"
        v-for="(item, l_index) in imgInfo.link_list"
        :key="l_index"
        :style="{
          'background-image': 'url(' + item.img + ')',
          'background-size': 'contain',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        }"
        @click="jumpLink(item)"
      ></div>
      <div
        class="register_bg"
        :style="{
          'background-image': 'url(' + imgInfo.register_bg + ')',
          'background-size': 'contain',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        }"
      ></div>
      <div class="register_btn" @click="handelRegis">
        <img :src="imgInfo.register_text_bg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { trackClick } from "@/tools/trackClick.js";
import { mapMutations, mapState } from "vuex";

export default {
  inject: ["deviceType"],
  data() {
    return {};
  },
  watch: {
    curActiveSlideIndex: {
      handler(val) {},
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState([
      "msg",
      "region",
      "registerVisible",
      "curActiveSlideIndex",
      "mobile",
    ]),

    language() {
      return this.$store.state.language;
    },

    navList() {
      return [];
    },
    imgInfo() {
      const imgObj = {
        TW: {
          icon_img: require("@/assets/header/tabbar_logo.png"),
          header_bg: require("@/assets/header/1_tabbar_bg.png"),
          link_list: [
            {
              name: "discord",
              link: "https://discord.gg/GCYza8vZ6y",
              img: require("@/assets/header/tabbar_discordlogo.png"),
            },
            {
              name: "youtube",
              link: "https://www.youtube.com/@BeastLordTheNewLand",
              img: require("@/assets/header/tabbar_ytbogo.png"),
            },
            {
              name: "facebook",
              link: "https://www.facebook.com/beastlordtw/",
              img: require("@/assets/header/tabbar_fblogo.png"),
            },
            {
              name: "line",
              link: "https://lin.ee/UkZC7Sg",
              img: require("@/assets/header/tabbar_linelogo.png"),
            },
          ],
          register_bg: require("@/assets/header/tabbar_button.png"),
          register_text_bg: require("@/assets/header/tabbar_button_tex.png"),
        },
      };
      const mobileImgObj = {
        TW: {
          icon_img: require("@/assets/mobile/home/tabbar_logo.png"),
          header_bg: require("@/assets/mobile/home/tabbar_bg.png"),
          link_list: [
            {
              name: "youtube",
              link: "https://www.youtube.com/@BeastLordTheNewLand",
              img: require("@/assets/mobile/home/tabbar_ytblogo.png"),
            },
            {
              name: "discord",
              link: "https://discord.gg/GCYza8vZ6y",
              img: require("@/assets/mobile/home/tabbar_discordlogo.png"),
            },
            {
              name: "facebook",
              link: "https://www.facebook.com/beastlordtw/",
              img: require("@/assets/mobile/home/tabbar_fblogo.png"),
            },
            {
              name: "line",
              link: "https://lin.ee/UkZC7Sg",
              img: require("@/assets/mobile/home/tabbar_linelogo.png"),
            },
          ],
          register_bg: require("@/assets/mobile/home/tabbar_button.png"),
          register_text_bg: require("@/assets/header/tabbar_button_tex.png"),
        },
      };
      return this.mobile ? mobileImgObj[this.region] : imgObj[this.region];
    },
  },
  methods: {
    ...mapMutations([
      "openNewMsg",
      "setRegisterVisibleStatus",
      "setCurrentJumpLink",
    ]),
    handelRegis() {
      this.$emit("handelViewRegisterArea");
    },
    /*语言切换 */
    jumpToNav(index) {
      this.showMenu = false;
      this.$emit("setCurrentIndex", index);
    },

    jumpLink(item) {
      let { name, link } = item;
      trackClick({
        behavior_tag: name,
      });
      this.setCurrentJumpLink(link);

      // window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
/* 定义动画细节 */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.header {
  width: 100vw;
  height: 5.56rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 99;
  padding-left: 2.13rem;
  // background-color: rgba(31, 100, 144, 0.61);
  // box-shadow: 0rem 1rem 2rem 0rem rgba(31, 100, 144, 0.35);
  &_icon {
    height: 90%;
    padding: 0.15rem 0;
    position: relative;
  }
  &_act {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 17.5rem;
    position: relative;
    .link {
      width: 3.5rem;
      height: 3.5rem;
      margin: 0 0.2rem;
      &:hover {
        cursor: pointer;
        transform: translateY(-0.1rem);
      }
    }
    .register_bg {
      width: 15.95rem;
      height: 9.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
    }
    .register_btn {
      width: 15.2rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      img {
        width: auto;
        height: 2.31rem;
      }
    }
  }
}
@media screen and (max-width: 1124px) {
  .header {
    width: 100vw;
    height: 7rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999999;
    padding-left: 0.4rem;
    // background-color: rgba(31, 100, 144, 0.61);
    // box-shadow: 0rem 1rem 2rem 0rem rgba(31, 100, 144, 0.35);
    &_icon {
      height: 100%;
      padding: 0.35rem 0;
      margin-left: 1.2rem;
    }
    &_act {
      width: auto;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 16.8rem;
      position: relative;
      .link {
        width: 3.75rem;
        height: 3.75rem;
        margin: 0 0.6rem;
        &:hover {
          cursor: pointer;
        }
      }
      .register_bg {
        width: 15.88rem;
        height: 10.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
      }
      .register_btn {
        width: 12rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        img {
          width: auto;
          height: 2.75rem;
          padding-right: 2rem;
        }
      }
    }
  }
}
</style>
