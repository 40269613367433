<template>
  <div
    class="home"
    :style="{
      'background-image': 'url(' + imgInfo.bg_img + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    }"
  >
    <div class="content">
      <div
        class="content_title bs_contain"
        :style="{
          'background-image': 'url(' + imgInfo.title_img + ')',
        }"
      >
        <img :src="imgInfo.ques_img" alt="" @click="showTipsModal" class="" />
      </div>
      <div
        class="content-area bs_100"
        :style="{
          'background-image': 'url(' + imgInfo.area_bg + ')',
        }"
      >
        <div class="content-area_left">
          <div class="lottery-container">
            <div
              v-for="(item, index) in imgInfo.prize_list"
              :key="index"
              :class="[
                'lottery-grid',
                'bs_contain',
                { highlight: currentIndex === index },
                { start: index === 4 },
              ]"
              v-loading="index === 4 && lotteryLoading"
              :style="{
                'background-image':
                  index !== 4
                    ? 'url(' + item.prize_img + ')'
                    : 'url(' + item.start_img + ')',
                cursor:
                  index === 4
                    ? lotteryLoading || isRunning
                      ? 'no-drop'
                      : 'pointer'
                    : 'default',
              }"
              @click="startLottery(index)"
            >
              <!-- <div
                class="price_img bs_contain"
                :style="{
                  'background-image':
                    index !== 4 ? 'url(' + item.prize_img + ')' : '',
                  width: item.width,
                }"
              ></div> -->
              <div v-if="index == 4" class="lottery-button">
                {{ item.name }}
              </div>

              <lotteryEffect
                v-if="currentIndex === index"
                class="lottery-effect"
              />
            </div>
          </div>
        </div>
        <div class="content-area_right">
          <div class="item">
            <div
              class="item_title1 bs_contain_lc"
              :style="{
                'background-image': 'url(' + imgInfo.link_title_bg + ')',
              }"
            ></div>
            <div class="item_link">
              <img
                :src="item.img"
                alt=""
                v-for="(item, index) in imgInfo.link_list"
                :key="index"
                @click="jumpLink(item)"
              />
            </div>
          </div>
          <div class="item">
            <div
              class="item_title2 bs_contain_lc"
              :style="{
                'background-image': 'url(' + imgInfo.share_title_bg + ')',
              }"
            ></div>
            <div class="item_share">
              <span class="tip">{{ $t("prize.$1") }}</span>
              <div class="share">
                <div
                  class="share_link bs_100"
                  :style="{
                    'background-image': 'url(' + imgInfo.share_link_bg + ')',
                  }"
                >
                  <span>{{
                    current_share_link ? current_share_link : $t("prize.$2")
                  }}</span>
                </div>
                <div
                  class="share_btn bs_contain"
                  ref="copyButton"
                  @click="copyToClipboard"
                  :style="{
                    'background-image': 'url(' + imgInfo.share_btn_bg + ')',
                  }"
                >
                  <span>{{ $t("prize.$3") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div
              class="item_title3 bs_contain_lc"
              :style="{
                'background-image': 'url(' + imgInfo.reward_title_bg + ')',
              }"
            ></div>
            <div class="item_reward">
              <img src="@/assets/pc/prize/bp_line.png" alt="" />
              <div class="reward_list">
                <div
                  class="card bs_cover"
                  v-for="(item, index) in imgInfo.reward_list"
                  :key="index"
                  :style="{
                    'background-image':
                      'url(' +
                      (currentPrizeCanDraw(item.id) ? item.bg : item.bg_done) +
                      ')',
                    cursor:
                      currentPrizeCanDraw(item.id) &&
                      lottery_source_share_length >= item.id
                        ? 'pointer'
                        : 'no-drop',
                  }"
                  @click="handelDrawInvitePrize(item.id)"
                >
                  <img
                    class="card_achieve"
                    :src="
                      currentPrizeCanDraw(item.id)
                        ? imgInfo.share_achieve
                        : imgInfo.share_not_achieve
                    "
                    alt=""
                    v-if="lottery_source_share_length >= item.id"
                  />
                  <InvitePrizeEffect
                    v-if="
                      currentPrizeCanDraw(item.id) &&
                      lottery_source_share_length >= item.id
                    "
                    class="invite-effect"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <img
              v-for="(item, index) in imgInfo.btn_list"
              :key="index"
              :src="item"
              alt=""
              @click="showModal(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <prizeRule
      :visible="prizeRuleVisible"
      @closeModal="prizeRuleVisible = false"
      @handelGetLottery="handelGetLottery"
      @handelTwitterGetLottery="handelTwitterGetLottery"
    />
    <prizeRecordModal
      :visible="prizeRecordVisible"
      @closeModal="prizeRecordVisible = false"
    />
    <drawInvitePrizeModal
      :visible="drawInvitePrizeInfo.visible"
      :cdk_code="drawInvitePrizeInfo.cdk_code"
      :id="drawInvitePrizeInfo.id"
      @closeModal="closeDrawInvitePrizeModal"
    />
    <registerSuccessModal
      :visible="registerSuccessInfo.visible"
      :type="registerSuccessInfo.type"
      :cdk_code="registerSuccessInfo.cdk_code"
      @closeModal="closeRegisTipsModal"
      @openLink="openLink"
    />
  </div>
</template>
  
  <script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
import { mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import ClipboardJS from "clipboard";
import prizeRecordModal from "./components/prizeRecordModal.vue";
import prizeRule from "./components/prizeRule.vue";
import lotteryEffect from "@/components/lotteryEffect";
import InvitePrizeEffect from "@/components/InvitePrizeEffect";
import {
  reqHandelLottery,
  reqReceivePrize,
  reqStoreReservation,
} from "@/settings/api.js";
import drawInvitePrizeModal from "@/views/PC/components/drawInvitePrizeModal";
import registerSuccessModal from "./components/registerSuccessModal.vue";

export default {
  name: "component_name",
  components: {
    prizeRecordModal,
    prizeRule,
    lotteryEffect,
    InvitePrizeEffect,
    drawInvitePrizeModal,
    registerSuccessModal,
  },
  data() {
    return {
      activePeopleNum: 0,
      highlightSequence: [0, 1, 2, 5, 8, 7, 6, 3], // 顺时针高亮顺序
      currentIndex: -1,
      isRunning: false,
      timer: null,
      current_share_link: "",
      prizeRecordVisible: false,
      prizeRuleVisible: false,
      lotteryLoading: false,
      drawInviteLoading: false,
      drawInvitePrizeInfo: {
        visible: false,
        cdk_code: "",
        id: null,
      },
      registerSuccessInfo: {
        visible: false,
        cdk_code: "",
        type: 2, // 关注公众号
      },
    };
  },
  watch: {
    copyUserInfo: {
      handler(val, old_val) {
        if (val.identity_id) {
          this.current_share_link = window.location.search
            ? `${window.location.href}&invite_id=${val.identity_id}`
            : `${window.location.href}?invite_id=${val.identity_id}`;
        }
        if (val?.identity_id && val?.identity_id !== old_val?.identity_id) {
          this.currentIndex = -1;
          this.isRunning = false;
          this.timer = null;
        }
      },
      immediate: true,
      deep: true,
    },
    currentJumpLink: {
      handler(val) {
        if (val) {
          console.log(
            "%c [  ]-282",
            "font-size:13px; background:pink; color:#bf2c9f;",
            val
          );
          this.handelGetLottery(1);
        }
      },
      immediate: true,
    },
  },
  created() {},
  computed: {
    ...mapState([
      "msg",
      "title",
      "region",
      "swiperEnable",
      "region_type",
      "user_info",
      "lottery_source",
      "drawReceiveList",
      "currentJumpLink",
    ]),

    imgInfo() {
      const imgObj = {
        TW: {
          bg_img: require("../../assets/pc/prize/bg4.png"),
          title_img: require("../../assets/pc/prize/bg_title.png"),
          ques_img: require("../../assets/pc/prize/bg_punct.png"),
          area_bg: require("../../assets/pc/prize/bp_big.png"),
          prize_bg: require("../../assets/pc/prize/bp_giftbg.png"),
          prize_gif: require("../../assets/pc/prize/test.gif"),
          prize_list: [
            {
              name: "Iphone 15",
              width: "6.5rem",
              probability: 0.0001,
              prize_img: require("@/assets/pc/prize/bp_gift1.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift1.png"),
            },
            {
              name: "AirPods",

              width: "7.13rem",
              probability: 0.005,
              prize_img: require("@/assets/pc/prize/bp_gift2.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift2.png"),
            },
            {
              name: "MyCard 300點數",

              width: "9rem",
              probability: 0.01,
              prize_img: require("@/assets/pc/prize/bp_gift3.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift3.png"),
            },
            {
              name: "獸王幣*100",
              width: "9rem",
              probability: 0.1,
              prize_img: require("@/assets/pc/prize/bp_gift6.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift6.png"),
            },
            {
              name: `(可用抽獎次數為：${this.user_info.can_lottery_num || 0})`,
              start_img: this.user_info.can_lottery_num
                ? require("@/assets/pc/prize/bp_button_normal_kscj.png")
                : require("@/assets/pc/prize/bp_button_normal_hccjcs.png"),
            },
            {
              name: "特級異獸誘餌*1",
              width: "10rem",
              probability: 0.1,
              prize_img: require("@/assets/pc/prize/bp_gift8.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift8.png"),
            },
            {
              name: "奇異結晶*100",
              width: "9rem",
              probability: 0.1,
              prize_img: require("@/assets/pc/prize/bp_gift5.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift5.png"),
            },
            {
              name: "用加速1H*2",
              width: "9rem",
              probability: 0.05,
              prize_img: require("@/assets/pc/prize/bp_gift4.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift4.png"),
            },

            {
              name: "鑽石*100",
              width: "9rem",
              probability: 0.1,
              prize_img: require("@/assets/pc/prize/bp_gift7.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift7.png"),
            },
          ],
          link_list: [
            {
              name: "discord",
              link: "https://discord.gg/GCYza8vZ6y",
              img: require("@/assets/pc/prize/bp_discordlogo.png"),
            },
            {
              name: "facebook",
              link: "https://www.facebook.com/beastlordtw/",
              img: require("@/assets/pc/prize/bp_fblogo.png"),
            },
            {
              name: "youtube",
              link: "https://www.youtube.com/@BeastLordTheNewLand",
              img: require("@/assets/pc/prize/bp_ytblogo.png"),
            },
            {
              name: "line",
              link: "https://lin.ee/UkZC7Sg",
              img: require("@/assets/pc/prize/bp_linelogo.png"),
            },
          ],
          link_title_bg: require("../../assets/pc/prize/bp_title1.png"),
          share_title_bg: require("../../assets/pc/prize/bp_title2.png"),
          reward_title_bg: require("../../assets/pc/prize/bp_title3.png"),
          share_link_bg: require("../../assets/pc/prize/bp_edit.png"),
          share_btn_bg: require("../../assets/pc/prize/bp_edit2.png"),
          reward_list: [
            {
              id: 1,
              bg: require("@/assets/pc/prize/bp_yq1.png"),
              bg_done: require("@/assets/pc/prize/bp_yq1_dis.png"),
            },
            {
              id: 2,
              bg: require("@/assets/pc/prize/bp_yq2.png"),
              bg_done: require("@/assets/pc/prize/bp_yq2_dis.png"),
            },
            {
              id: 3,
              bg: require("@/assets/pc/prize/bp_yq3.png"),
              bg_done: require("@/assets/pc/prize/bp_yq3_dis.png"),
            },
          ],
          share_achieve: require("@/assets/pc/prize/kelingqu.png"),
          share_not_achieve: require("@/assets/pc/prize/yilingqu.png"),
          btn_list: [
            require("@/assets/pc/prize/bp_button_pre_hdgz.png"),
            require("@/assets/pc/prize/bp_button_pre_zjcx.png"),
          ],
        },
      };
      return imgObj[this.region];
    },
    lottery_num() {
      return this.user_info.can_lottery_num || 0;
    },
    lottery_source_share_length() {
      return (
        this.lottery_source.filter((item) => {
          return item === 3;
        })?.length || 0
      );
    },
    copyUserInfo() {
      return cloneDeep(this.user_info);
    },
    // 当前邀请奖励是否可被领取？
    currentPrizeCanDraw() {
      return (id) => {
        const hasDrawArr = this.drawReceiveList.map((item) => {
          return item?.invited_level;
        });
        return hasDrawArr.includes(id) ? false : true;
      };
    },
  },
  mounted() {},

  methods: {
    ...mapMutations([
      "openNewMsg",
      "setSwiperStatus",
      "setSwiperActiveIndex",
      "refreshCanLotteryNum",
      "setUserInfo",
      "setCurrentJumpLink",
    ]),
    isInRange(value, min, max) {
      return value >= min && value < max;
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Download Ios package",
        });
        window.open("https://apps.apple.com/app/id6450451551", "_blank");
      } else {
        trackClick({
          behavior_tag: "Download Android package",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.tw",
          "_blank"
        );
      }
    },
    closeRegisTipsModal() {
      this.registerSuccessInfo.visible = false;
      this.registerSuccessInfo.type = 2;
    },
    // 直接跳转推特增加次数
    handelTwitterGetLottery(id) {
      if (!this.user_info.identity_id) {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t(`msg.login_tips`),
          autoClose: true,
          time: 2000,
        });
        this.setSwiperActiveIndex(1);
        return;
      } else {
        window.open("https://www.facebook.com/beastlordtw/", "_blank");
        this.handelGetLottery(1);
      }
    },
    handelGetLottery(id) {
      this.setSwiperStatus(true);
      this.prizeRuleVisible = false;

      if (id === 0) {
        this.setSwiperActiveIndex(1);
      } else if (id === 1) {
        // 有链接需要跳转，同时排除 ’media_source‘  的跳转，只做次数增加
        if (this.currentJumpLink && this.currentJumpLink !== "media_source") {
          window.open(this.currentJumpLink, "_blank");
          this.setCurrentJumpLink("");
        }
        // 登录状态才可进行
        if (!this.user_info.identity_id) {
          // this.openNewMsg({
          //   title: this.$t("msg.title"),
          //   body: this.$t(`msg.login_tips`),
          //   autoClose: true,
          //   time: 2000,
          // });
          // this.setSwiperActiveIndex(1);
          return;
        } else {
          // 判断是否已经通过跳转获得过次数
          if (this.lottery_source.includes(2)) {
            return;
          }
        }
        reqStoreReservation({
          app_id: +process.env.VUE_APP_PROJECT_ID,
          identity_id: this.user_info.identity_id,
          region_type: this.region_type,
        })
          .then((res) => {
            if (res.code === 20000) {
              let { can_lottery_num, cdk_code, obtain_lottery_num } = res.data;
              this.setUserInfo({ can_lottery_num });
              this.refreshCanLotteryNum();
              if (obtain_lottery_num) {
                this.setSwiperActiveIndex(3);
                this.registerSuccessInfo = {
                  visible: true,
                  cdk_code,
                  type: 2,
                };
              }
            } else {
              this.openNewMsg({
                title: this.$t("msg.title"),
                body: this.$t(`errMsg.$${res.code}`),
                autoClose: true,
                time: 1000,
              });
            }
          })
          .finally(() => {
            this.setCurrentJumpLink("");
          });
      }
    },
    startLottery(ind) {
      const cur_date = new Date().getTime();
      const end_date = new Date("2024-07-18 24:00:00").getTime();

      if (cur_date >= end_date) {
        this.$tipModal({
          type: "type6",
          onClose: () => {},
        });
        return;
      }
      if (!this.user_info.can_lottery_num && ind === 4) {
        this.$tipModal({
          type: "type1",
          onClose: () => {},
        });
        return;
      }
      if (this.isRunning || ind !== 4 || this.lotteryLoading) return;
      trackClick({
        behavior_tag: "Lucky Draw",
      });
      this.lotteryLoading = true;
      reqHandelLottery({
        app_id: +process.env.VUE_APP_PROJECT_ID,
        identity_id: this.user_info.identity_id,
        region_type: this.region_type,
      })
        .then((res) => {
          if (res.code === 20000) {
            let { lottery_level } = res.data;
            this.isRunning = true;

            // 确定奖品索引
            this.prizeIndex = this.selectPrize(lottery_level);

            let speed = 50; // 初始速度
            const minSpeed = 800; // 最小速度
            const speedIncrement = 10; // 每一次变慢的增量
            const duration = 5000; // 总时长

            let currentStepIndex = 0;
            const startTime = Date.now();

            const runLottery = () => {
              this.currentIndex = this.highlightSequence[currentStepIndex];
              currentStepIndex =
                (currentStepIndex + 1) % this.highlightSequence.length;

              // 计算已经过去的时间
              let elapsedTime = Date.now() - startTime;

              // 根据时间计算速度
              if (elapsedTime < duration) {
                // 随着时间推移，减慢速度
                speed += speedIncrement;
                if (speed > minSpeed) speed = minSpeed;
              } else {
                // 时间到了，检查当前是否为选中的奖品
                if (this.currentIndex === this.prizeIndex) {
                  clearTimeout(this.timer);
                  this.isRunning = false;
                  trackClick({
                    behavior_tag: "Lucky Draw",
                    prize: this.imgInfo.prize_list[this.prizeIndex].name || "",
                  });
                  this.refreshCanLotteryNum();
                  this.$tipModal({
                    type: "type5",
                    reward: this.imgInfo.prize_list[this.prizeIndex],
                    onClose: () => {},
                  });
                  return;
                }
              }

              this.timer = setTimeout(runLottery, speed);
            };

            runLottery();
          } else {
            this.refreshCanLotteryNum();
            this.openNewMsg({
              title: this.$t("msg.title"),
              body: this.$t(`errMsg.$${res.code}`),
              autoClose: true,
              time: 1000,
            });
          }
        })
        .finally(() => {
          this.lotteryLoading = false;
        });
    },
    selectPrize(index) {
      // 如果因为概率计算问题没有选中任何奖品，则默认返回最后一个
      return index < 5 ? index - 1 : index;
    },
    copyToClipboard() {
      const _this = this;
      // 使用一个新的 Clipboard 实例来复制文本
      const clipboard = new ClipboardJS(this.$refs.copyButton, {
        text: () => this.current_share_link,
      });

      clipboard.on("success", (e) => {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t("msg.copy_success"),
          autoClose: true,
          time: 1000,
        });
        trackClick({
          behavior_tag: "Copy Link",
        });
        e.clearSelection(); // 清除高亮选择
        clipboard.destroy(); // 复制成功后释放资源
      });

      clipboard.on("error", (e) => {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t("msg.copy_fail"),
          autoClose: true,
          time: 1000,
        });
        clipboard.destroy(); // 遇到错误后释放资源
      });

      // 触发按钮的点击事件以启动复制操作
      this.$refs.copyButton.click();
    },
    showTipsModal() {
      this.$tipModal({
        type: "type3",
        onClose: () => {},
      });
    },
    showModal(ind) {
      if (ind) {
        this.prizeRecordVisible = true;
        this.setSwiperStatus(false);
      } else {
        const cur_date = new Date().getTime();
        const end_date = new Date("2024-07-18 24:00:00").getTime();

        if (cur_date >= end_date) {
          this.$tipModal({
            type: "type6",
            onClose: () => {},
          });
          return;
        }
        this.prizeRuleVisible = true;
        this.setSwiperStatus(false);
      }
    },
    handelDrawInvitePrize(id) {
      if (
        this.currentPrizeCanDraw(id) &&
        this.lottery_source_share_length >= id
      ) {
        if (this.drawInviteLoading) {
          return;
        }
        this.drawInviteLoading = true;
        reqReceivePrize({
          app_id: +process.env.VUE_APP_PROJECT_ID,
          identity_id: this.user_info.identity_id,
          invited_level: id,
          region_type: this.region_type,
        })
          .then((res) => {
            if (res.code === 20000) {
              let { cdk_code } = res.data;
              this.$parent.$parent.getInviteSource();
              this.drawInvitePrizeInfo = {
                visible: true,
                cdk_code,
                id,
              };
            } else {
              this.openNewMsg({
                title: this.$t("msg.title"),
                body: this.$t(`errMsg.$${res.code}`),
                autoClose: true,
                time: 1000,
              });
            }
          })
          .finally(() => {
            this.drawInviteLoading = false;
          });
      }
    },
    closeDrawInvitePrizeModal() {
      this.drawInvitePrizeInfo.visible = false;
      this.drawInvitePrizeInfo.cdk_code = "";
      this.drawInvitePrizeInfo.id = null;
    },
    jumpLink(item) {
      let { name, link } = item;
      trackClick({
        behavior_tag: name,
      });
      this.setCurrentJumpLink(link);
    },
  },
  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },

  destroyed() {},
};
</script>
  
  <style lang="scss" scoped>
@media screen and (max-width: 1449px) {
  .lottery-effect {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.2, 1.25) !important;
  }
}

.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: calc(100% - 5.56rem);
    margin-top: 5.56rem;
    position: relative;
    &_title {
      width: 49.53%;
      // height: 12.75%;
      height: 7.5rem;

      position: absolute;
      top: 2.5%;
      left: 50%;
      transform: translate(-50%, 0);
      img {
        position: absolute;
        right: 26%;
        top: 12%;
        cursor: pointer;
      }
    }
    &-area {
      width: 61.825%;
      // height: 80.63%;
      height: 44rem;

      display: flex;

      position: absolute;
      left: 50%;
      transform: translate(-50%, 0) scale(0.95);
      top: 13.47%;
      box-sizing: border-box;
      padding: 2.5% 3%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &_left {
        width: 58%;
        height: 100%;
        .lottery-container {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-columns: repeat(3, calc((100% - 1rem) / 3));
          grid-template-rows: repeat(3, calc((100% - 1.5rem) / 3));
          grid-row-gap: 0.75rem;
          grid-column-gap: 0.5rem;
          justify-content: center;
        }
        .lottery-grid {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid transparent;

          position: relative;
          transform: scale(1.05);

          .price_img {
            width: 8rem;
            height: 8rem;
            margin-bottom: 1.5rem;
          }
          .prize_name {
            font-size: 0.7rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: 13%;
            white-space: nowrap;
          }
        }
        .highlight {
          position: relative;
          // border: 1px solid red;
          // background-image: url("../../assets//pc//prize/test3.gif") !important;
          .lottery-effect {
            // width: 100%;
            // height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(1.14, 1.28);
            // top: -10%;
            // left: -8%;
            // width: 116%;
            // height: 120%;
          }
        }
        .start {
          cursor: pointer;
          transform: translate(0, 2.5%) scale(1.18);
          &:hover {
            transform: translate(0, 2.5%) scale(1.19);
          }
        }
        .lottery-button {
          font-size: 0.73rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #edcd5e;
          line-height: 0.97rem;

          background: linear-gradient(0deg, #723723 0%, #502919 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: 25%;
          white-space: nowrap;
        }
      }
      &_right {
        width: 37%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .item {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          &_title1 {
            width: 100%;
            height: 1.8rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          &_link {
            width: 100%;
            height: 2.2rem;
            padding-left: 2rem;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 1rem;
            img {
              height: 100%;
              margin-right: 0.8rem;
              cursor: pointer;
            }
          }
          &_title2 {
            width: 12rem;
            height: 1.8rem;
            margin-top: 1rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          &_share {
            width: 100%;
            height: auto;
            padding-left: 2rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 1rem;
            .tip {
              height: 1.06rem;
              line-height: 1.06rem;
              font-size: 1.06rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;

              background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .share {
              width: 100%;
              height: 1.8rem;
              display: flex;
              margin-top: 0.65rem;
              justify-content: space-between;
              align-items: center;
              &_link {
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  height: 0.75rem;
                  font-size: 0.75rem;
                  line-height: 0.75rem;

                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                  width: 90%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              &_btn {
                width: 28%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                span {
                  height: 0.75rem;
                  line-height: 0.75rem;
                  font-size: 0.75rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
            }
          }
          &_title3 {
            width: 80%;
            height: 1.7rem;
            margin-top: 1rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          &_reward {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;
            margin-top: 1rem;

            padding-left: 0.7rem;
            box-sizing: border-box;
            img {
              width: 0.25rem;
              height: 96%;
            }
            .reward_list {
              width: calc(100% - 1rem);
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .card {
                width: 100%;
                height: 5.6rem;
                margin-bottom: 0.5rem;
                position: relative;
                &:last-child {
                  margin-bottom: 0;
                }
                .card_achieve {
                  position: absolute;
                  width: 4rem;
                  height: 4rem;
                  right: -0.8rem;
                  top: -1.6rem;
                }
                .invite-effect {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%) scale(1.18, 1.45);
                }
              }
            }
          }
        }
        .btn {
          width: calc(100% - 0rem);
          height: 3.56rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 1.5rem;
          margin-top: 0.5rem;
          box-sizing: border-box;
          img {
            width: 10rem;
            &:hover {
              transform: scale(1.05);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
  