<template>
  <div class="footer_cls">
    <div class="tw_right">
      <div class="logo_cls" style="margin-right: 1%">
        <img
          src="@/assets/logo_1.png"
          style="width: 2.667rem; height: 2.667rem; margin-right: 0.267rem"
          alt="logo"
        />
        <div>
          <p>Copyright © 2022~2025 版權所有</p>
          <p :style="{ marginTop: 0 }">
            <a
              href="https://static-sites.allstarunion.com/service/general_privacy_tw.html"
              target="_blank"
              >{{ $t("register.agree2") }}</a
            >
            ｜
            <a
              href="https://static-sites.allstarunion.com/service/general_protocol_tw.html"
              target="_blank"
              >{{ $t("register.agree1") }}</a
            >
            <!-- ｜
              <span class="cur_pointer" @click="connUs">聯絡我們</span> -->
          </p>
        </div>
      </div>
      <div class="logo_cls">
        <img
          src="@/assets/logo_2.png"
          style="width: 3.933rem; height: 3.933rem; margin-right: -0.667rem"
          alt="logo"
        />
        <div style="margin: 1rem 0 0 0.267rem">
          <p>※ 本軟體因涉及暴力，依遊戲軟體分級管理辦法分類為輔 12 級。</p>
          <p>
            ※ 本遊戲為免費使用，遊戲內另提供購買虛擬遊戲幣、物品等付費服務。
          </p>
          <p>※ 長時間進行遊戲，請注意使用時間，避免沉迷於遊戲。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style lang="scss" scoped>
.footer_cls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #000;
  // padding: 2rem 0 1rem 0;

  min-width: 100%;
  z-index: 1020000;
  height: 5.25rem;
  position: absolute;
  bottom: 0;
  .tw_right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .logo_cls {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        text-align: left;
        color: #8a8a8a;
        font-size: 0.933rem;

        p {
          margin: 0.133rem 0.133rem 0 0.1333rem;

          a {
            color: #8a8a8a;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
