<template>
  <div id="app" @click="showEffect($event)">
    <TheHeader @handelViewRegisterArea="handelViewRegisterArea" />
    <Index v-if="deviceType === 'pc'" ref="pcIndex"></Index>
    <Mobile v-if="deviceType !== 'pc'" ref="mobileIndex"></Mobile>
    <!-- 动画容器 -->
    <div v-if="showEffectFlag" :style="effectStyle" class="effect-animation">
      <!-- 循环显示序列帧图片 -->
      <img
        v-for="(frame, index) in frames"
        :key="index"
        :src="frame"
        :class="{ active: currentFrame === index }"
        alt="Animation frame"
      />
    </div>
  </div>
</template>

<script>
import Index from "./views/PcIndex.vue";
import Mobile from "./views/MobileIndex.vue";
import TheHeader from "./components/TheHeader.vue";
import webStorage from "./tools/setTimeStorage";
import { trackClick } from "./tools/trackClick.js";
import {
  reportData,
  reqCurrentIp,
  reqLotterySource,
  reqLotteryWinning,
} from "./settings/api";
import { signer } from "@/settings/crypto.js";
import { convertFormdata } from "@/tools/formdata.js";
import { mapMutations, mapState } from "vuex";

import { json } from "body-parser";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default {
  name: "App",
  components: { Index, Mobile, TheHeader },
  data() {
    return {
      deviceType: "",
      interval: null,
      timer: null, //全局定时器
      // residenceTimer: null, // 记录用户停留时长的定时器s

      frames: [], // 存储序列帧图片路径数组
      currentFrame: 0, // 当前帧索引
      showEffectFlag: false, // 控制动画的显示与隐藏
      effectStyle: { top: "0px", left: "0px" }, // 动画位置
    };
  },

  provide() {
    return {
      deviceType: () => this.deviceType,
    };
  },
  async created() {
    if (localStorage.getItem("user_info")) {
      let { can_lottery_num, area_code, email, identity_id, invite_id } =
        JSON.parse(localStorage.getItem("user_info"));
      this.setUserInfo({ can_lottery_num });
      this.setUserInfo({ identity_id });
      this.setUserInfo({ area_code });
      this.setUserInfo({ email });
      this.setUserInfo({ invite_id });
      if (identity_id) {
        this.refreshCanLotteryNum();
      }
    }
    this.handelGetCurrentIp();
    await this.getDeviceId();

    this.judgeDeviceType();

    this.$i18n.locale = "tw";
    localStorage.setItem("lang", "tw");
    // 定时上报数据，2分钟
    this.timer = setInterval(() => {
      let data = webStorage.getItem("localStorage", "tc");
      let blob;
      if (data && data.constructor === Object && data.trackClick) {
        blob = new Blob([data.trackClick], { type: "application/json" });
        this.uploadData(blob);
      } else if (data && data.constructor === Array) {
        blob = new Blob([JSON.stringify(data)], { type: "application/json" });
        this.uploadData(blob);
      }
    }, 30 * 1000);
  },

  computed: {
    ...mapState(["msg", "region", "user_info", "region_type"]),
    language() {
      return this.$store.state.language;
    },
  },
  watch: {
    user_info: {
      handler(val) {
        if (val?.identity_id) {
          this.getLotterySource();
          this.getInviteSource();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    const _this = this;
    this.setVhVariable();
    const url_info = this.getRequest(window.location.search);
    const hasInviteId = url_info && url_info?.invite_id;
    this.setUserInfo({ invite_id: hasInviteId ? url_info?.invite_id : "" });
    let new_obj = Object.assign(
      JSON.parse(localStorage.getItem("user_info")) || {},
      { invite_id: hasInviteId ? url_info?.invite_id : "" }
    );
    localStorage.setItem("user_info", JSON.stringify(new_obj));

    // 从外部进入也需要增加次数
    if (url_info && url_info?.media_source) {
      localStorage.setItem("media_source", url_info?.media_source);
      if (localStorage.getItem("user_info")) {
        let { identity_id } = JSON.parse(localStorage.getItem("user_info"));
        if (identity_id) {
          this.setCurrentJumpLink("media_source");
        }
      }
    } else {
      localStorage.setItem("media_source", "");
    }

    this.loadFrames(); // 预加载序列帧图片
    //过期时间为24h，初次浏览或超过一天后刷新，记作浏览
    if (!webStorage.getItem("sessionStorage", "ll")) {
      webStorage.setItem("sessionStorage", "ll", true, 24 * 60 * 60000);
      this.$nextTick(() => {
        trackClick({
          behavior_tag: "Browse Page",
        });
      });
    }
    // 窗口尺寸变化重新判定机型
    window.onresize = () =>
      (() => {
        this.judgeDeviceType();
        this.setVhVariable();
        console.log(
          "%c [ 设备尺寸  ]-156",
          "font-size:13px; background:pink; color:#bf2c9f;",
          window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        );
      })();
    let beginTime = 0; //开始时间
    let differTime = 0; //时间差
    window.onunload = async function () {
      differTime = new Date().getTime() - beginTime;
      console.log("onunload", new Date().getTime(), differTime);

      if (differTime <= 5) {
        // localStorage.removeItem("time");
        console.log("关闭");
        let data = webStorage.getItem("localStorage", "tc");
        let copyData = [];
        let copyLastValue = {};
        if (data && data.constructor === Object && data.trackClick) {
          console.log(5555, data.trackClick);
          copyLastValue = data.trackClick[0];
          copyData = [...data.trackClick];
        } else if (data && data.constructor === Array) {
          copyLastValue = data[0];
          copyData = [...data];
        }
        copyLastValue.browsing_time = localStorage.getItem("time");
        copyLastValue.behavior_tag = "Leave Page";
        copyData.push(copyLastValue);
        localStorage.setItem("aa", JSON.stringify(copyData));

        let blob;
        if (
          copyData &&
          copyData.constructor === Object &&
          copyData.trackClick
        ) {
          blob = new Blob([copyData.trackClick], { type: "application/json" });
        } else if (copyData && copyData.constructor === Array) {
          blob = new Blob([JSON.stringify(copyData)], {
            type: "application/json",
          });
        }
        let fixData = {
          file: blob,
          stream_prefix: process.env.VUE_APP_STREAM_PREFIX,
        };

        const { ts, seed, sign } = signer(fixData);
        fixData.ts = ts;
        fixData.seed = seed;
        fixData.signature = sign;
        let formData = convertFormdata(fixData);
        navigator.sendBeacon(
          `${process.env.VUE_APP_BASE_API}/api/data`,

          formData
        );

        localStorage.removeItem("time");
        localStorage.removeItem("tc");
      } else {
        console.log("刷新");
      }
    };
    let time = localStorage.getItem("time")
      ? JSON.parse(localStorage.getItem("time"))
      : 0;
    if (!document.hidden) {
      this.residenceTimer = setInterval(() => {
        time++;
        localStorage.setItem("time", JSON.stringify(time));
      }, 1000);
    }

    window.addEventListener("beforeunload", (event) => {
      beginTime = new Date().getTime();

      //页面刷新前将vuex里的状态数据保存到sessionStorage
      if (localStorage.getItem("user_info")) {
        localStorage.setItem(
          "user_info",
          JSON.stringify(Object.assign({}, this.user_info))
        );
      }
    });
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) {
        if (localStorage.getItem("user_info")) {
          localStorage.setItem(
            "user_info",
            JSON.stringify(Object.assign({}, this.user_info))
          );
        }
        _this.$store.commit("SET_REFRESH_PAGE", true);
        localStorage.removeItem("time");
        clearInterval(this.residenceTimer);
        this.residenceTimer = null;
        time = 0;
        this.residenceTimer = setInterval(() => {
          time++;
          localStorage.setItem("time", JSON.stringify(time));
        }, 1000);
      } else {
        clearInterval(this.residenceTimer);
        let t = JSON.parse(localStorage.getItem("time"));
        setTimeout(() => {
          if (_this.$store.state.refreshPage) {
            console.log("页面停留时间", t);
            clearInterval(this.residenceTimer);
            if (t >= 5) {
              trackClick({
                stay_secs: +t,
                behavior_tag: "Stay Page",
              });
            }
          } else {
            _this.$store.commit("SET_REFRESH_PAGE", true);
          }
        }, 100);
      }
    });
    // window.addEventListener("load", this.getTiming(), false);
  },
  methods: {
    ...mapMutations([
      "setMobile",
      "setUserInfo",
      "refreshCanLotteryNum",
      "setLotterySource",
      "setDrawReceiveList",
      "openNewMsg",
      "setCurrentJumpLink",
    ]),
    //  兼容方案
    setVhVariable() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    isMobile() {
      const flag =
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        ) || document.body.clientWidth < 800;
      return flag;
    },
    isIos() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isiOS ? "ios" : "android";
    },
    judgeDeviceType() {
      if (this.isMobile()) {
        this.deviceType = this.isIos();
        this.setMobile(true);
      } else {
        this.deviceType = "pc";
        this.setMobile(false);
      }
      this.$store.commit("SET_DEVICE_TYPE", this.deviceType);
    },

    uploadData(file) {
      reportData(
        { file, stream_prefix: process.env.VUE_APP_STREAM_PREFIX },
        true
      ).then((res) => {
        console.log("上报成功=>", res);
        localStorage.removeItem("tc");
      });
    },
    // 获取当前ip
    handelGetCurrentIp() {
      if (window.ip) {
        return;
      }
      reqCurrentIp({}).then((res) => {
        if (res.code === 20000) {
          let { ip, country, iso_code } = res.data.addr;
          window.ip = ip;
          window.iso_code = iso_code;
          this.setUserInfo({ ip: ip });
        }
      });
    },
    // 获取当前设备号
    getDeviceId() {
      return new Promise((resolve, reject) => {
        FingerprintJS.load().then((fp) => {
          fp.get().then((result) => {
            const visitorId = result.visitorId;
            console.log(
              "%c [ 设备号 ]-258",
              "font-size:13px; background:pink; color:#bf2c9f;",
              visitorId
            );
            this.setUserInfo({ device: visitorId });
            resolve();
          });
        });
      });
    },
    loadFrames() {
      this.frames = [
        require("@/assets/click/bandicam_00000.png"),
        require("@/assets/click/bandicam_00001.png"),
        require("@/assets/click/bandicam_00002.png"),
        require("@/assets/click/bandicam_00003.png"),
        require("@/assets/click/bandicam_00004.png"),
        require("@/assets/click/bandicam_00005.png"),
        require("@/assets/click/bandicam_00006.png"),
        require("@/assets/click/bandicam_00007.png"),
        require("@/assets/click/bandicam_00008.png"),
        require("@/assets/click/bandicam_00009.png"),
        require("@/assets/click/bandicam_00010.png"),
        require("@/assets/click/bandicam_00011.png"),
        require("@/assets/click/bandicam_00012.png"),
        require("@/assets/click/bandicam_00013.png"),
        require("@/assets/click/bandicam_00014.png"),
        require("@/assets/click/bandicam_00015.png"),
        require("@/assets/click/bandicam_00016.png"),
        require("@/assets/click/bandicam_00017.png"),
        require("@/assets/click/bandicam_00018.png"),
        require("@/assets/click/bandicam_00019.png"),
        require("@/assets/click/bandicam_00020.png"),
        require("@/assets/click/bandicam_00021.png"),
        require("@/assets/click/bandicam_00022.png"),
        require("@/assets/click/bandicam_00023.png"),
        require("@/assets/click/bandicam_00024.png"),
        require("@/assets/click/bandicam_00025.png"),
        require("@/assets/click/bandicam_00026.png"),
        require("@/assets/click/bandicam_00027.png"),
        require("@/assets/click/bandicam_00028.png"),
        require("@/assets/click/bandicam_00029.png"),
        // ...
      ];
    },
    showEffect(event) {
      const x = event.clientX;
      const y = event.clientY;
      this.effectStyle = { top: `${y - 50}px`, left: `${x - 50}px` }; // 设置动画的起始位置
      this.showEffectFlag = true;
      this.currentFrame = 0;

      let intervalId = setInterval(() => {
        if (this.currentFrame < this.frames.length - 1) {
          this.currentFrame++;
        } else {
          clearInterval(intervalId); // 结束动画
          this.showEffectFlag = false; // 隐藏动画容器
        }
      }, 1000 / 30); // 根据帧率计算时间间隔，这里假设是每秒 24 帧
    },
    handelViewRegisterArea() {
      if (this.isMobile()) {
        this.$nextTick(() => {
          this.$refs.mobileIndex.handleScrollToRegister();
        });
      } else {
        this.$nextTick(() => {
          this.$refs.pcIndex.setSlideIndex(1);
        });
      }
    },
    getRequest(url) {
      // var url = window.location.search; //获取url中"?"符后的字串
      let theRequest = new Object();
      // 判断是否有问号
      if (url.indexOf("?") != -1) {
        // 把问号去掉
        var str = url.substr(1);
        // 判断是否有多个参数
        if (str.indexOf("&") != -1) {
          // 以&符分项组成数组
          let strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            // 循环数组
            // theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            // 每一项等号左边为属性，等号右边为属性的值，值需要使用 decodeURI() 函数对通过 escape() 或 url 编码过的字符串进行解码。
            theRequest[strs[i].split("=")[0]] = decodeURI(
              strs[i].split("=")[1]
            );
          }
        } else {
          // 只有一个参数
          let arr = str.split("=");
          theRequest[arr[0]] = arr[1];
        }
      }
      return theRequest;
    },
    getLotterySource() {
      reqLotterySource({
        app_id: +process.env.VUE_APP_PROJECT_ID,
        identity_id: this.user_info.identity_id,
        region_type: this.region_type,
      }).then((res) => {
        if (res.code === 20000) {
          let { source } = res.data;
          this.setLotterySource(source || []);
        } else {
          this.openNewMsg({
            title: this.$t("msg.title"),
            body: this.$t(`errMsg.$${res.code}`),
            autoClose: true,
            time: 1000,
          });
        }
      });
    },
    getInviteSource() {
      reqLotteryWinning({
        app_id: +process.env.VUE_APP_PROJECT_ID,
        identity_id: this.user_info.identity_id,
        region_type: this.region_type,
      }).then((res) => {
        if (res.code === 20000) {
          let arr = res.data?.list || [];
          let invitePrizeArr = arr.filter((item) => {
            return item.reward_type === 2;
          });

          this.setDrawReceiveList(invitePrizeArr || []);
        } else {
          this.openNewMsg({
            title: this.$t("msg.title"),
            body: this.$t(`errMsg.$${res.code}`),
            autoClose: true,
            time: 1000,
          });
        }
      });
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.cur_pointer {
  cursor: pointer;
}
div,
a,
img {
  -webkit-tap-highlight-color: transparent;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden !important;
}
.effect-animation {
  position: absolute;
  pointer-events: none; /* 确保动画不会拦截鼠标事件 */
  /* 设置动画大小、其他样式 */
  z-index: 999999;
  img.active {
    display: block;
  }

  img {
    display: none;
    width: 100px;
    height: 100px;
  }
}

.nav-title {
  color: #739439;
  font-size: 2.1rem;
  display: block;
  flex-grow: 1;
  width: 100%;
  padding: 0.7rem 0.5rem;
}
.language_option {
  font-size: 1.4rem;
  color: rgb(120, 154, 57);
  text-align: left;
  padding: 2% 0 2% 9%;
  border-bottom: 0.18rem solid #789a39;
}
.language_option:nth-last-of-type(1) {
  border: none;
}
.cur_language {
  background-color: #739439;
  color: #fff;
}
.back_img_cls {
  background-image: url("https://static-sites.allstarunion.com/official_web_static/sprite.png");
}
.font-color {
  color: #789a39;
}
.font-color_v2 {
  color: #ffe775;
}
.position-f {
  position: relative;
}
.position-c {
  position: absolute;
}
</style>
