<template>
  <div
    class="register"
    :style="{
      'background-image': 'url(' + imgInfo.bg_img + ')',
      'background-size': '100% 100%',
      'background-repeat': 'no-repeat',
      'background-position': 'center top',
    }"
  >
    <div
      class="register_content"
      :style="{
        'background-image': 'url(' + imgInfo.content_bg + ')',
        'background-size': '100% 100%',
        'background-repeat': 'no-repeat',
        'background-position': 'center top',
      }"
    >
      <img class="title" :src="imgInfo.title_bg" alt="" />

      <img
        class="q_mark"
        src="@/assets/pc/register/bp_title_punct.png"
        alt=""
        @click="showTipsModal"
      />
      <div class="area">
        <span class="area_tip">{{ $t("register.title1") }}</span>
        <div class="area_phone">
          <div
            class="area_phone_select"
            @mouseenter="showAreaOptionsModal = true"
            @mouseleave="showAreaOptionsModal = false"
          >
            <span class="area_code">{{ countAreaCodeLabel }}</span>
            <i
              class="el-icon-caret-bottom"
              :class="{
                arrow_bt: !showAreaOptionsModal,
                arrow_top: showAreaOptionsModal,
              }"
            ></i>
            <transition name="el-zoom-in-top">
              <div class="area_code_options" v-show="showAreaOptionsModal">
                <div class="line"></div>
                <div
                  class="item"
                  :class="{ act_item: item.value === area_code }"
                  v-for="item in options"
                  :key="item.value"
                  @click="choiceAreaCode(item)"
                >
                  <span>
                    {{ item.label }}
                  </span>
                </div>
              </div>
            </transition>
          </div>
          <el-input
            class="area_phone_input"
            v-model="email"
            :placeholder="$t('register.tip')"
            :disabled="disabled"
          ></el-input>
        </div>

        <div class="area_check">
          <el-checkbox v-model="choice">
            <span>{{ $t("register.agree") }}</span>
          </el-checkbox>
        </div>
        <div class="area_agree">
          <span @click="viewAgreement(1)">{{ $t("register.agree1") }}</span>
          <span @click="viewAgreement(2)"> {{ $t("register.agree2") }}</span>
        </div>

        <div
          class="area_btn"
          :style="{
            'background-image':
              'url(' +
              (isViewPrizeStatus ? imgInfo.btn_done_img : imgInfo.btn_img) +
              ')',
          }"
          @click="handelRegister"
        >
          <!-- <span>{{ isViewPrizeStatus ? "" : $t("register.regis") }}</span> -->
        </div>
        <div class="area_award">
          <div
            class="item bs_contain"
            v-for="(item, index) in imgInfo.award_list"
            :key="index"
            :style="{
              'background-image': 'url(' + item + ')',
            }"
          ></div>
        </div>
        <div class="area_download">
          <img
            v-for="(item, index) in imgInfo.link_list"
            :key="index"
            :src="item.img"
            alt=""
            @click="openLink(item.name)"
          />
        </div>
        <span class="area_tips">
          {{ $t("register.tip4") }}
        </span>
      </div>
    </div>
    <img :src="imgInfo.left_top_bg" alt="" class="left_top_bg" />
    <img :src="imgInfo.left_bot_bg" alt="" class="left_bot_bg" />
    <img :src="imgInfo.right_bg" alt="" class="right_bg" />
    <registerSuccessModal
      :visible="registerSuccessInfo.visible"
      :type="registerSuccessInfo.type"
      :cdk_code="registerSuccessInfo.cdk_code"
      @closeModal="closeRegisTipsModal"
      @openLink="openLink"
    />
  </div>
</template>

<script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick.js";
import { mapMutations, mapState } from "vuex";
import registerSuccessModal from "./components/registerSuccessModal.vue";
import { reqReservation, reqStoreReservation } from "@/settings/api.js";
export default {
  name: "component_name",
  components: {
    registerSuccessModal,
  },
  data() {
    return {
      cover: true,
      show: false,
      choice: false,
      email: "",
      area_code: "",
      showAreaOptionsModal: false,
      disabled: false,
      registerSuccessInfo: {
        visible: false,
        cdk_code: "",
        type: 1, //1  事前登录   2商店注册
      },
      options: [
        {
          value: "886",
          label: "臺灣+886",
        },
        {
          value: "853",
          label: "澳門+853",
        },
        {
          value: "852",
          label: "香港+852",
        },
      ],
    };
  },
  inject: ["deviceType"],

  created() {},
  computed: {
    ...mapState(["msg", "region", "region_type", "user_info"]),
    countAreaCodeLabel() {
      return this.area_code
        ? this.options.find((item) => {
            return item.value === this.area_code;
          })?.label
        : "请选择";
    },
    imgInfo() {
      const imgObj = {
        TW: {
          bg_img: require("@/assets/pc/register/bg.png"),
          content_bg: require("@/assets/pc/register/bp.png"),
          title_bg: require("@/assets/pc/register/bp_title.png"),
          btn_img: require("@/assets/pc/register/anniupre.png"),
          btn_done_img: require("@/assets/pc/register/anniu_normal.png"),
          left_top_bg: require("@/assets/pc/register/bg_huhou3.png"),
          left_bot_bg: require("@/assets/pc/register/bg_huhou2.png"),
          right_bg: require("@/assets/pc/register/bg_huhou1.png"),
          award_list: [
            require("@/assets/pc/register/gif1.png"),
            require("@/assets/pc/register/gif2.png"),
            require("@/assets/pc/register/gif3.png"),
          ],
          link_list: [
            {
              name: "google",
              img: require("@/assets/pc/register/bp_download_googleplay.png"),
            },
            {
              name: "ios",
              img: require("@/assets/pc/register/bp_download_appstore.png"),
            },
          ],
        },
      };
      return imgObj[this.region];
    },
    // 是否是查看奖励的状态
    isViewPrizeStatus() {
      let { email, identity_id, area_code } = this.user_info;
      if (identity_id && this.email === email && this.area_code === area_code) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
  watch: {
    user_info: {
      handler(val) {
        if (val.email && val.area_code) {
          this.email = val.email;
          this.area_code = val.area_code;
          this.choice = true;
          // this.disabled = true;
        } else {
          this.email = "";
          this.area_code = "";

          this.choice = false;
          // this.disabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations([
      "openNewMsg",
      "setSwiperStatus",
      "setUserInfo",
      "refreshCanLotteryNum",
    ]),
    choiceAreaCode(item) {
      let { value } = item;
      if (this.area_code === value) {
        return;
      }
      this.area_code = value;
    },
    showTipsModal() {
      this.$tipModal({
        type: "type4",
        onClose: () => {},
      });
    },
    closeRegisTipsModal() {
      this.registerSuccessInfo.visible = false;
      this.registerSuccessInfo.type = 1;
    },
    handelRegister: debounce(function () {
      // 到期屏蔽注册
      const cur_date = new Date().getTime();
      const end_date = new Date("2024-07-18 24:00:00").getTime();

      if (cur_date >= end_date) {
        this.$tipModal({
          type: "type6",
          onClose: () => {},
        });
        return;
      }
      const _this = this;
      const title = _this.$t("msg.title");
      if (!this.area_code) {
        this.openNewMsg({
          title,
          body: "請先選擇區號",
          autoClose: true,
          time: 1000,
        });
        return;
      }
      let reg;
      if (this.area_code === "886") {
        // 台湾手机号码：格式一般为 09xx-xxxxxx。共有 10 位数字，且以 09 开头。
        reg = /^09\d{8}$/;
      } else if (this.area_code === "853") {
        // 澳门手机号码：格式一般为 xxxx-xxxx。共有 8 位数字，通常以 6 开头。
        reg = /^6\d{7}$/;
      } else if (this.area_code === "852") {
        // 通常由 8 位数字组成，而且多数情况下以数字 5, 6, 或 9 开头
        reg = /^(5|6|9)\d{7}$/;
      }
      if (!reg.test(this.email)) {
        this.openNewMsg({
          title,
          body: "請輸入正確的電話號碼",
          autoClose: true,
          time: 1000,
        });
      } else {
        if (this.isViewPrizeStatus) {
          // this.registerSuccessInfo = {
          //   visible: true,
          //   cdk_code: localStorage.getItem("email_cdk_code"),
          //   type: 1,
          // };
          return;
        }
        if (!this.choice) {
          this.openNewMsg({
            title,
            body: this.$t("register.agree"),
            autoClose: true,
            time: 1000,
          });
          return;
        }

        reqReservation({
          app_id: +process.env.VUE_APP_PROJECT_ID,
          device: this.user_info?.device,
          // device: new Date().getTime().toString(),
          ip: this.user_info?.ip,
          email: `${this.area_code}${this.email}`,
          invited_id: this.user_info?.invite_id || undefined,
          region_type: this.region_type,
        }).then((res) => {
          if (res.code === 20000 || res.code === 20001) {
            let { can_lottery_num, cdk_code, identity_id } = res.data;
            _this.setUserInfo({ can_lottery_num });
            _this.setUserInfo({ identity_id });
            _this.setUserInfo({ email: _this.email });
            _this.setUserInfo({ area_code: _this.area_code });

            _this.refreshCanLotteryNum();
            trackClick(
              {
                behavior_tag: "Submit Email",
              },
              true
            );
            // if (cdk_code) {
            //   localStorage.setItem("email_cdk_code", cdk_code);
            //   this.registerSuccessInfo = {
            //     visible: true,
            //     cdk_code,
            //     type: 1,
            //   };
            // }
          } else {
            this.openNewMsg({
              title: this.$t("msg.title"),
              body: this.$t(`errMsg.$${res.code}`),
              autoClose: true,
              time: 1000,
            });
          }
        });
      }
    }, 600),
    jumpLink(way) {
      let urlObj = {
        ios: {
          link: "https://apps.apple.com/app/id6475638006",
          track_name: "Click Ios store",
        },
        google: {
          link: "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.jp",
          track_name: "Click Google store",
        },
        twitter: {
          link: "https://twitter.com/NoDgameJP",
          track_name: "Click Twitter",
        },
        // wait
        youtube: {
          link: "https://www.youtube.com/@NoDgames",
          track_name: "Click Youtube",
        },
        // wait
        line: {
          link: "https://lin.ee/J9fiO9G",
          track_name: "Click Line",
        },
      };
      let url = urlObj[way].link;
      window.open(url, "_blank");
      // window.open(url, "_blank");
      trackClick({
        behavior_tag: urlObj[way].track_name,
      });
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Download Ios package",
        });
        window.open("https://apps.apple.com/app/id6450451551", "_blank");
      } else {
        trackClick({
          behavior_tag: "Download Android package",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.tw",
          "_blank"
        );
      }
    },
    closeModal() {
      this.show = false;
      this.$emit("hideRegister", true);
      this.$emit("changeRightNav", true);
      this.$emit("hiddenBottomDirIcon", true);
    },
    showModal() {
      this.show = true;
      this.$emit("changeRightNav", false);
    },
    viewAgreement(type) {
      switch (type) {
        case 1:
          window.open(
            "https://static-sites.allstarunion.com/service/general_protocol_tw.html",
            "_blank"
          );
          break;
        case 2:
          window.open(
            "https://static-sites.allstarunion.com/service/general_privacy_tw.html",
            "_blank"
          );
          break;
        default:
          break;
      }
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.register {
  width: 100%;
  height: calc(100% - 0rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .el-icon-close {
    position: absolute;
    top: 0rem;
    right: 0rem;
    height: 1.7rem;
    width: 1.7rem;
    font-size: 1.7rem;
    cursor: pointer;
    color: #fff;
    cursor: pointer;
  }
  &_content {
    padding-top: 5.56rem;
    width: 35%;
    height: 42rem;
    position: absolute;
    left: calc(50% - 4.75rem);
    top: calc(50% + 3.58rem);
    transform: translate(-50%, -50%);
    z-index: 99;
    .title {
      position: absolute;
      top: -3.8rem;
      left: 2.8%;
      width: 20.81rem;
    }
    .q_mark {
      position: absolute;
      top: -1.8rem;
      left: 19.5rem;
      height: 1.4rem;
      cursor: pointer;
    }

    .area {
      width: 71.45%;
      height: 100%;
      position: absolute;
      right: 10%;
      top: 13%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &_tip {
        font-size: 2.66rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        background: linear-gradient(180deg, #ffffff 0%, #decc9e 99.365234375%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        white-space: nowrap;
      }
      &_phone {
        width: 100%;
        height: 2.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2.5rem;

        &_select {
          width: 10.25rem;
          height: 100%;
          border: 1px solid;
          border-image: linear-gradient(0deg, #d3c583, #d9d09d) 10 10;
          background: linear-gradient(0deg, #5d858f 0%, #274d54 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          .area_code {
            font-size: 1.1rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
          }
          .el-icon-caret-bottom {
            color: #fff;
            margin-left: 1rem;
          }
          .arrow_bt {
            transform: rotate(0);
            transition: all 0.3s ease-in-out;
          }
          .arrow_top {
            transform: rotate(180deg);
            transition: all 0.3s ease-in-out;
          }
          .area_code_options {
            width: calc(100% + 2px);
            height: auto;
            position: absolute;
            z-index: 99;
            left: -1px;
            top: calc(2.7rem - 2px);
            border: 1px solid;
            box-sizing: border-box;
            border-image: linear-gradient(0deg, #d3c583, #d9d09d) 10 10;
            background: linear-gradient(0deg, #274d54 0%, #5d858f 100%);
            border-top: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .line {
              width: 90%;
              height: 0.5px;
              background-color: #fff;
              position: absolute;
              top: 0;
              left: 5%;
            }
            .item {
              width: 100%;
              height: 2.7rem;
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              &:hover {
                background-color: RGBA(255, 255, 255, 0.2);
                cursor: pointer;
              }
              span {
                font-size: 1.1rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
              }
            }
            .act_item {
              background-color: RGBA(255, 255, 255, 0.2);
            }
          }
        }
        &_input {
          width: calc(100% - 12.25rem);
          ::v-deep {
            .el-input__inner {
              width: 97.5%;
              height: 2.7rem;
              border: 1px solid;
              border-image: linear-gradient(0deg, #d3c583, #d9d09d) 10 10;
              background: linear-gradient(0deg, #5d858f 0%, #274d54 100%);
              font-size: 1.1rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              &::placeholder {
                width: 100%;
                text-align: center;
                font-size: 1rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }

      &_check {
        width: 100%;
        height: 0.88rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        ::v-deep {
          .el-checkbox {
            display: flex;
            align-items: center;
          }
          .el-checkbox__inner {
            border: 1px solid #d3c583;
            background-color: transparent;
          }
          .el-checkbox__label {
            font-size: 0.84rem;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #ffffff;
            padding: 0;
            padding-left: 0.3rem;
            padding-bottom: 0.1rem;
            height: 0.88rem;
            line-height: 0.88rem;
            font-weight: normal;
            span {
              height: 0.88rem;
              line-height: 0.88rem;
            }
          }
        }
      }
      &_agree {
        width: 100%;
        height: 0.88rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.3rem;
        span {
          font-size: 0.88rem;
          font-family: Source Han Sans CN;
          color: #ffffff;
          text-decoration: underline;
          cursor: pointer;
          &:last-child {
            margin-left: 0.88rem;
          }
        }
      }
      &_btn {
        width: 12rem;
        height: 3.88rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.75rem;
        &:hover {
          transform: scale(1.05);
          cursor: pointer;
        }
        span {
          font-size: 1.57rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0rem 0rem 0rem rgba(3, 8, 0, 0.85);
          margin-bottom: 0.5rem;
          user-select: none;
        }
      }
      &_award {
        width: 100%;
        height: 10.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
        .item {
          width: 11.15rem;
          height: 100%;
          transform: scale(1.2);
        }
      }
      &_download {
        height: 3.9rem;
        width: 97%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.6rem;
        img {
          height: 100%;
          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }
          &:first-child {
            width: 13.5rem;
          }
          &:last-child {
            width: 11rem;
            margin-left: 2.25rem;
          }
        }
      }
      &_tips {
        width: auto;
        height: 1rem;
        margin-top: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: 300;
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .left_top_bg {
    width: 43rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  .left_bot_bg {
    width: 38rem;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .right_bg {
    width: 48.5em;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media screen and (min-height: 650px) and (max-height: 750px) {
  .register_content {
    height: 41rem !important;
  }
}
</style>
