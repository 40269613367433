var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header",style:({
    backgroundColor: !_vm.mobile
      ? _vm.registerVisible || _vm.curActiveSlideIndex
        ? 'RGBA(10, 16, 11, 1)'
        : ''
      : '',
    'background-image':
      'url(' + (_vm.mobile ? _vm.imgInfo.header_bg : _vm.imgInfo.header_bg) + ')',
    'background-size': '100% 140%',
    'background-repeat': 'no-repeat',
    'background-position': 'center top',
  })},[_c('img',{staticClass:"header_icon",attrs:{"src":_vm.imgInfo.icon_img,"alt":""}}),_c('div',{staticClass:"header_act"},[_vm._l((_vm.imgInfo.link_list),function(item,l_index){return _c('div',{key:l_index,staticClass:"link",style:({
        'background-image': 'url(' + item.img + ')',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }),on:{"click":function($event){return _vm.jumpLink(item)}}})}),_c('div',{staticClass:"register_bg",style:({
        'background-image': 'url(' + _vm.imgInfo.register_bg + ')',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      })}),_c('div',{staticClass:"register_btn",on:{"click":_vm.handelRegis}},[_c('img',{attrs:{"src":_vm.imgInfo.register_text_bg,"alt":""}})])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }