import Vue from "vue";
import Vuex from "vuex";
import msgBox from "../components/msgbox/msgbox";
import { reqCanLotteryNum } from "@/settings/api";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    language: "tw",
    msg: {},
    mobile: document.documentElement.clientWidth > 768,
    t: null,
    deviceType: "pc",
    refreshPage: null,
    title: "ヒント",
    region: "TW",
    region_type: 3, // 地区 1日 2韩 3台
    showIndirection: true,
    swiperEnable: true,
    registerVisible: false,
    curActiveSlideIndex: 0,
    forbidMobileScroll: false,
    // 当前预约人信息
    user_info: {
      ip: null,
      area_code: "",
      email: null,
      device: null,
      invite_id: null,
      can_lottery_num: null,
      identity_id: null,
    },
    lottery_source: [],
    drawReceiveList: [], // 已领取的邀请奖励列表
    currentJumpLink: "",
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language;
    },

    SET_DEVICE_TYPE: (state, str) => {
      state.deviceType = str;
    },
    SET_REFRESH_PAGE: (state, status) => {
      state.refreshPage = status;
    },
    SET_REGION: (state, region) => {
      state.region = region;
    },
    SET_INDIRECTION_STATUS: (state, status) => {
      state.showIndirection = status;
    },
    setSwiperStatus: (state, status) => {
      state.swiperEnable = status;
    },
    setRegisterVisibleStatus: (state, status) => {
      state.registerVisible = status;
    },
    setSwiperActiveIndex: (state, value) => {
      state.curActiveSlideIndex = value;
    },
    openNewMsg(state, msgObj) {
      if (state.msg && state.msg.autoClose) {
        state.msg.closeMsg();
        if (state.t) {
          clearTimeout(state.t);
        }
      }
      state.msg = new msgBox({
        ...msgObj,
        visible: false,
      });
      state.msg.autoClose = msgObj.autoClose;
      state.msg.openMsg();
      if (msgObj.autoClose) {
        state.t = setTimeout(() => {
          state.msg.closeMsg();
          state.msg.autoClose = false;
          state.t = null;
        }, msgObj.time || 6500);
      }
    },
    setMobile(state, mob) {
      state.mobile = mob;
    },
    setForbidMobileScrollStatus(state, status) {
      state.forbidMobileScroll = status;
    },

    setUserInfo(state, obj) {
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        state.user_info[key] = obj[key];
      });
      if (localStorage.getItem("user_info")) {
        localStorage.setItem(
          "user_info",
          JSON.stringify(Object.assign({}, state.user_info))
        );
      }
    },
    refreshCanLotteryNum(state) {
      reqCanLotteryNum({
        app_id: +process.env.VUE_APP_PROJECT_ID,
        identity_id: state.user_info.identity_id,
        region_type: state.region_type,
      }).then((res) => {
        if (res.code === 20000) {
          state.user_info.can_lottery_num = res.data.can_lottery_num;
        }
      });
    },
    setLotterySource(state, arr) {
      state.lottery_source = arr;
    },
    setDrawReceiveList(state, arr) {
      state.drawReceiveList = arr;
    },

    setCurrentJumpLink(state, value) {
      state.currentJumpLink = value;

      console.log(
        "%c [  ]-116",
        "font-size:13px; background:pink; color:#bf2c9f;",
        state.currentJumpLink
      );
    },
  },
  actions: {},
  modules: {},
});
