<template>
  <div class="carousel">
    <div class="carousel_title bs_cover">
      <img :src="imgInfo.title_img" alt="" />
    </div>
    <div class="video-container">
      <el-carousel
        indicator-position="none"
        :interval="4000"
        type="card"
        :autoplay="false"
        class="your-carousel"
        arrow="never"
        v-model="activeIndex"
        @change="carouselChange"
        ref="carousel"
      >
        <el-carousel-item v-for="(item, i) in imgInfo.info_list" :key="i">
          <div
            :style="{
              'background-image':
                'url(' +
                (i === activeIndex ? item.head_hover : item.head_def) +
                ')',
            }"
          ></div>
        </el-carousel-item>
      </el-carousel>
      <div class="button-prev" @click="changeActiveIndex('prev')"></div>
      <div class="button-next" @click="changeActiveIndex('next')"></div>
    </div>
    <div class="pagination">
      <span
        :class="i === activeIndex ? 'active-slide' : 'inactive-slide'"
        v-for="(item, i) in 5"
        :key="i"
        @click="setCurCarousel(i)"
      ></span>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import TheFooter from "@/components/TheFooter";
import { trackClick } from "@/tools/trackClick.js";
import { mapMutations, mapState } from "vuex";

export default {
  name: "component_name",
  components: {
    TheFooter,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  created() {},
  computed: {
    ...mapState(["msg", "title", "region", "swiperEnable"]),
    imgInfo() {
      const imgObj = {
        TW: {
          title_img: require("@/assets/mobile/carousel/title.png"),
          info_list: [
            {
              head_def: require("@/assets/mobile/carousel/pic1_2.png"),
              head_hover: require("@/assets/mobile/carousel/pic1.png"),
            },
            {
              head_def: require("@/assets/mobile/carousel/pic2_2.png"),
              head_hover: require("@/assets/mobile/carousel/pic2.png"),
            },
            {
              head_def: require("@/assets/mobile/carousel/pic3_2.png"),
              head_hover: require("@/assets/mobile/carousel/pic3.png"),
            },
            {
              head_def: require("@/assets/mobile/carousel/pic4_2.png"),
              head_hover: require("@/assets/mobile/carousel/pic4.png"),
            },
            {
              head_def: require("@/assets/mobile/carousel/pic5_2.png"),
              head_hover: require("@/assets/mobile/carousel/pic5.png"),
            },
          ],
        },
      };
      return imgObj[this.region];
    },
  },
  mounted() {
    const _this = this;
    this.$refs.carousel.$on("before-leave", this.beforeLeaveHandler);
  },

  methods: {
    carouselChange(v) {
      this.activeIndex = v;
    },
    changeActiveIndex(way) {
      if (way === "next") {
        this.$refs.carousel.next();
      } else {
        this.$refs.carousel.prev();
      }
    },
    setCurCarousel(ind) {
      this.$refs.carousel.setActiveItem(ind);
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
  height: 95rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: -15rem;
  &_title {
    width: 26.22rem;
    // height: 7.65%;

    img {
      width: 100%;
    }
  }
}
::v-deep .el-carousel {
  width: 100%;
  height: 100%;
}
::v-deep .el-carousel__container {
  width: 100%;
  height: 100% !important;
  /* 中间轮播项 */
}
::v-deep .el-carousel__mask {
  background-color: transparent !important;
}
::v-deep .is-active {
  width: 100% !important;
  margin-left: -25% !important;
}

.video-container {
  width: calc(100% - 4rem);
  height: 72rem;

  margin-top: 6.5rem;
  position: relative;
  .el-carousel__item div {
    width: 100%;
    height: 100%;
    // background-size: 100% 100%;
    background-size: contain;

    background-position: center;
    background-repeat: no-repeat;
  }

  .button {
    &-prev {
      width: 6rem;
      height: 11.7rem;
      background-image: url("~@/assets/mobile/carousel/arrow.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translate(0, -50%);
      cursor: pointer;
      z-index: 999;
      &:hover {
        transform: scale(1.1) translate(0, -50%);
      }
    }
    &-next {
      width: 6rem;
      height: 11.7rem;
      background-image: url("~@/assets/mobile/carousel/arrow2.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      z-index: 999;
      &:hover {
        transform: scale(1.1) translate(0, -50%);
      }
    }
  }
}
.pagination {
  width: calc(100% - 48rem);
  height: 2.5rem;
  margin-top: 3.45rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  span {
    width: 2.5rem;
    height: 100%;
    display: inline-block;
    background-image: url("~@/assets/pc/carousel/bg_sign_2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .active-slide {
    background-image: url("~@/assets/pc/carousel/bg_sign.png");
  }
}
</style>
