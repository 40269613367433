var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register",style:({
    'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
    'background-size': '100% 100%',
    'background-repeat': 'no-repeat',
    'background-position': 'center top',
  })},[_c('div',{staticClass:"register_content",style:({
      'background-image': 'url(' + _vm.imgInfo.content_bg + ')',
      'background-size': '100% 100%',
      'background-repeat': 'no-repeat',
      'background-position': 'center top',
    })},[_c('img',{staticClass:"title",attrs:{"src":_vm.imgInfo.title_bg,"alt":""}}),_c('img',{staticClass:"q_mark",attrs:{"src":require("@/assets/pc/register/bp_title_punct.png"),"alt":""},on:{"click":_vm.showTipsModal}}),_c('div',{staticClass:"area"},[_c('span',{staticClass:"area_tip"},[_vm._v(_vm._s(_vm.$t("register.title1")))]),_c('div',{staticClass:"area_phone"},[_c('div',{staticClass:"area_phone_select",on:{"mouseenter":function($event){_vm.showAreaOptionsModal = true},"mouseleave":function($event){_vm.showAreaOptionsModal = false}}},[_c('span',{staticClass:"area_code"},[_vm._v(_vm._s(_vm.countAreaCodeLabel))]),_c('i',{staticClass:"el-icon-caret-bottom",class:{
              arrow_bt: !_vm.showAreaOptionsModal,
              arrow_top: _vm.showAreaOptionsModal,
            }}),_c('transition',{attrs:{"name":"el-zoom-in-top"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAreaOptionsModal),expression:"showAreaOptionsModal"}],staticClass:"area_code_options"},[_c('div',{staticClass:"line"}),_vm._l((_vm.options),function(item){return _c('div',{key:item.value,staticClass:"item",class:{ act_item: item.value === _vm.area_code },on:{"click":function($event){return _vm.choiceAreaCode(item)}}},[_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])])})],2)])],1),_c('el-input',{staticClass:"area_phone_input",attrs:{"placeholder":_vm.$t('register.tip'),"disabled":_vm.disabled},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"area_check"},[_c('el-checkbox',{model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}},[_c('span',[_vm._v(_vm._s(_vm.$t("register.agree")))])])],1),_c('div',{staticClass:"area_agree"},[_c('span',{on:{"click":function($event){return _vm.viewAgreement(1)}}},[_vm._v(_vm._s(_vm.$t("register.agree1")))]),_c('span',{on:{"click":function($event){return _vm.viewAgreement(2)}}},[_vm._v(" "+_vm._s(_vm.$t("register.agree2")))])]),_c('div',{staticClass:"area_btn",style:({
          'background-image':
            'url(' +
            (_vm.isViewPrizeStatus ? _vm.imgInfo.btn_done_img : _vm.imgInfo.btn_img) +
            ')',
        }),on:{"click":_vm.handelRegister}}),_c('div',{staticClass:"area_award"},_vm._l((_vm.imgInfo.award_list),function(item,index){return _c('div',{key:index,staticClass:"item bs_contain",style:({
            'background-image': 'url(' + item + ')',
          })})}),0),_c('div',{staticClass:"area_download"},_vm._l((_vm.imgInfo.link_list),function(item,index){return _c('img',{key:index,attrs:{"src":item.img,"alt":""},on:{"click":function($event){return _vm.openLink(item.name)}}})}),0),_c('span',{staticClass:"area_tips"},[_vm._v(" "+_vm._s(_vm.$t("register.tip4"))+" ")])])]),_c('img',{staticClass:"left_top_bg",attrs:{"src":_vm.imgInfo.left_top_bg,"alt":""}}),_c('img',{staticClass:"left_bot_bg",attrs:{"src":_vm.imgInfo.left_bot_bg,"alt":""}}),_c('img',{staticClass:"right_bg",attrs:{"src":_vm.imgInfo.right_bg,"alt":""}}),_c('registerSuccessModal',{attrs:{"visible":_vm.registerSuccessInfo.visible,"type":_vm.registerSuccessInfo.type,"cdk_code":_vm.registerSuccessInfo.cdk_code},on:{"closeModal":_vm.closeRegisTipsModal,"openLink":_vm.openLink}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }