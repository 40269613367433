<template>
  <div class="home">
    <div
      class="content bs_cover"
      :style="{
        'background-image': 'url(' + imgInfo.bg_img + ')',
      }"
    >
      <div class="content_title bs_cover">
        <img :src="imgInfo.title_img" alt="" />
      </div>
      <div class="video-container">
        <el-carousel
          indicator-position="none"
          :interval="4000"
          type="card"
          :autoplay="false"
          class="your-carousel"
          arrow="never"
          v-model="activeIndex"
          @change="carouselChange"
          ref="carousel"
        >
          <el-carousel-item v-for="(item, i) in imgInfo.info_list" :key="i">
            <div
              :style="{
                'background-image':
                  'url(' +
                  (i === activeIndex ? item.head_hover : item.head_def) +
                  ')',
              }"
            ></div>
          </el-carousel-item>
        </el-carousel>
        <div class="button-prev" @click="changeActiveIndex('prev')"></div>
        <div class="button-next" @click="changeActiveIndex('next')"></div>
        <div class="pagination">
          <span
            :class="i === activeIndex ? 'active-slide' : 'inactive-slide'"
            v-for="(item, i) in 5"
            :key="i"
            @click="setCurCarousel(i)"
          ></span>
        </div>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import TheFooter from "@/components/TheFooter";
import { trackClick } from "@/tools/trackClick.js";
import { mapMutations, mapState } from "vuex";

export default {
  name: "component_name",
  components: {
    TheFooter,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  created() {},
  computed: {
    ...mapState(["msg", "title", "region", "swiperEnable"]),
    imgInfo() {
      const imgObj = {
        TW: {
          bg_img: require("@/assets/pc/carousel/bg.png"),
          title_img: require("@/assets/pc/carousel/title.png"),
          info_list: [
            {
              head_def: require("@/assets/pc/carousel/pic1_2.png"),
              head_hover: require("@/assets/pc/carousel/pic1.png"),
            },
            {
              head_def: require("@/assets/pc/carousel/pic2_2.png"),
              head_hover: require("@/assets/pc/carousel/pic2.png"),
            },
            {
              head_def: require("@/assets/pc/carousel/pic3_2.png"),
              head_hover: require("@/assets/pc/carousel/pic3.png"),
            },
            {
              head_def: require("@/assets/pc/carousel/pic4_2.png"),
              head_hover: require("@/assets/pc/carousel/pic4.png"),
            },
            {
              head_def: require("@/assets/pc/carousel/pic5_2.png"),
              head_hover: require("@/assets/pc/carousel/pic5.png"),
            },
          ],
        },
      };
      return imgObj[this.region];
    },
  },
  mounted() {
    const _this = this;
    this.$refs.carousel.$on("before-leave", this.beforeLeaveHandler);
  },

  methods: {
    carouselChange(v) {
      this.activeIndex = v;
    },
    changeActiveIndex(way) {
      if (way === "next") {
        this.$refs.carousel.next();
      } else {
        this.$refs.carousel.prev();
      }
    },
    setCurCarousel(ind) {
      this.$refs.carousel.setActiveItem(ind);
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(9, 14, 10);

  .content {
    width: 100%;
    height: calc(100% - 5.56rem);
    margin-top: 5.56rem;
    position: relative;
    &_title {
      width: 19.675%;
      // height: 7.65%;
      position: absolute;
      top: 6%;
      left: 49.5%;
      transform: translate(-50%, 0);
      img {
        width: 100%;
      }
    }
  }
}
::v-deep .el-carousel {
  width: 100%;
  height: 100%;
}
::v-deep .el-carousel__container {
  width: 100%;
  height: 100% !important;
  /* 中间轮播项 */
}
::v-deep .el-carousel__mask {
  background-color: transparent !important;
}
::v-deep .is-active {
  width: 76% !important;
  margin-left: -12% !important;
}
.video-container {
  width: 65%;
  height: 60.35%;
  // background-image: url("../../assets/pc/video/content-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, 0);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  .el-carousel__item div {
    width: 100%;
    height: 100%;
    // background-size: 100% 100%;
    background-size: contain;

    background-position: center;
    background-repeat: no-repeat;
  }

  .button {
    &-prev {
      width: 4.7rem;
      height: 5.4rem;
      background-image: url("~@/assets/pc/carousel/bg_arrow.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 3rem;
      transform: translate(0, -50%);
      cursor: pointer;
      z-index: 999;
      &:hover {
        transform: scale(1.1) translate(0, -50%);
      }
    }
    &-next {
      width: 4.7rem;
      height: 5.4rem;
      background-image: url("~@/assets/pc/carousel/bg_arrow2.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 3rem;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      z-index: 999;
      &:hover {
        transform: scale(1.1) translate(0, -50%);
      }
    }
  }
  .pagination {
    width: 14.25rem;
    height: 2rem;
    position: absolute;
    bottom: -2.5rem;
    left: 50%;
    margin-left: -7.125rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    span {
      width: 2rem;
      height: 100%;
      display: inline-block;
      background-image: url("~@/assets/pc/carousel/bg_sign_2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .active-slide {
      background-image: url("~@/assets/pc/carousel/bg_sign.png");
    }
  }
}
</style>
