<template>
  <div class="home">
    <div
      class="content"
      :style="{
        'background-image': 'url(' + imgInfo.bg_img + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }"
    >
      <!-- <img class="content-img" :src="imgInfo.intro_img" alt="" /> -->
      <div
        class="content-img"
        :style="{
          'background-image': 'url(' + imgInfo.intro_img + ')',
          'background-size': 'contain',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        }"
      ></div>
      <div class="content_info">
        <div class="intro-btn">
          <img class="head" :src="imgInfo.head_img" alt="" />
          <div class="download">
            <img
              class="download_btn"
              :src="item.img"
              alt=""
              v-for="(item, img_index) in imgInfo.link_list"
              :key="img_index"
              @click="openLink(item.name)"
            />
          </div>
          <img
            class="play"
            :src="imgInfo.paly_bg"
            @click="openVideoModal"
            alt=""
          />
        </div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="video" v-if="showVideoModal">
        <div class="video-center">
          <img
            class="close_img"
            @click="closeVideoModal"
            src="@/assets/mobile/popup/popup_closebutton.png"
            alt=""
          />

          <video :src="countLanguageVideo" ref="video" controls></video>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { trackClick } from "@/tools/trackClick.js";
import { debounce } from "@/tools/debounce.js";
import { mapMutations, mapState } from "vuex";
import pcVideo from "@/assets/video/video_p_tw.mp4";
export default {
  name: "component_name",
  components: {},
  data() {
    return {
      activeNavList: [false, false, false, false, false],
      cover: true,
      show: false,
      choice: false,
      email: "",
      showVideoModal: false,
      countLanguageVideo: pcVideo,
    };
  },
  inject: ["deviceType"],
  created() {},
  computed: {
    ...mapState(["msg", "title", "region"]),
    imgInfo() {
      const imgObj = {
        TW: {
          bg_img: require("../../assets/pc/home/home_bg.png"),
          intro_img: require("@/assets/pc/home/home_label.png"),
          head_img: require("@/assets/pc/home/home_icon.png"),
          link_list: [
            {
              name: "google",
              img: require("@/assets/pc/home/home_download_googleplay.png"),
            },
            {
              name: "ios",
              img: require("@/assets/pc/home/home_download_appstore.png"),
            },
          ],
          paly_bg: require("@/assets/pc/home/home_play_pre.png"),
        },
      };
      return imgObj[this.region];
    },
  },
  mounted() {},

  methods: {
    ...mapMutations(["openNewMsg"]),
    openVideoModal() {
      this.showVideoModal = true;
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },
    closeVideoModal() {
      this.$refs.video.pause();
      this.showVideoModal = false;
    },
    handelLogin() {
      this.$refs.emailInput.focus();
    },
    changeRightNav(v) {
      this.$emit("changeRightNav", v);
    },
    handelTouchIcon(way, i) {
      this.activeNavList = this.activeNavList.map((item, ind) => {
        if (ind === i) {
          item = way == "cover" ? true : false;
        }
        return item;
      });
    },
    jumpLink(way) {
      let urlObj = {
        ios: {
          link: "https://apps.apple.com/app/id6475638006",
          track_name: "Click Ios store",
        },
        google: {
          link: "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.jp",
          track_name: "Click Google store",
        },
        twitter: {
          link: "https://twitter.com/NoDgameJP",
          track_name: "Click Twitter",
        },
        // wait
        youtube: {
          link: "https://www.youtube.com/@NoDgames",
          track_name: "Click Youtube",
        },
        // wait
        line: {
          link: "https://lin.ee/J9fiO9G",
          track_name: "Click Line",
        },
      };
      let url = urlObj[way].link;
      window.open(url, "_blank");
      // window.open(url, "_blank");
      trackClick({
        behavior_tag: urlObj[way].track_name,
      });
    },

    closeDirection(v) {
      console.log(124124, v);
      this.$emit("hiddenBottomDirIcon", v);
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Download Ios package",
        });
        window.open("https://apps.apple.com/app/id6450451551", "_blank");
      } else {
        trackClick({
          behavior_tag: "Download Android package",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.tw",
          "_blank"
        );
      }
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
@keyframes scale_move {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: 100%;
    position: relative;
    &-img {
      // width: 120%;
      // height: 28.2rem;
      width: 51.9%;
      height: 46.8%;
      position: absolute;
      right: 0.1rem;
      bottom: 20.65%;
    }
    &_info {
      width: 43.9%;
      height: auto;
      position: absolute;
      right: 3.1rem;
      bottom: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      .intro-btn {
        width: 100%;
        height: 11.5rem;
        margin-top: 1.8rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .head {
          width: 11.5rem;
          height: 11.5rem;
          border-radius: 0.8rem;
        }
        .download {
          width: 17.38rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin: 0 1.55rem;
          &_btn {
            width: 100%;
            height: 5.2rem;
            &:hover {
              cursor: pointer;
              transform: scale(1.05);
            }
          }
        }
        .play {
          width: 8.2rem;
          height: 8.2rem;
          animation: scale_move 1.5s linear infinite;
          animation-play-state: running;
          &:hover {
            cursor: pointer;
            animation-play-state: paused;
          }
        }
      }
    }
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100001;
    display: flex;
    justify-content: center;
    align-items: center;

    &-center {
      width: 62.5rem;
      height: 31.5rem;
      position: relative;
      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 0.5rem;
      }
      .close_img {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: -3.5rem;
        right: -3rem;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
      }
    }
  }
}
</style>
