import { render, staticRenderFns } from "./registerSuccessModal.vue?vue&type=template&id=64f08114&scoped=true&"
import script from "./registerSuccessModal.vue?vue&type=script&lang=js&"
export * from "./registerSuccessModal.vue?vue&type=script&lang=js&"
import style0 from "./registerSuccessModal.vue?vue&type=style&index=0&lang=css&"
import style1 from "./registerSuccessModal.vue?vue&type=style&index=1&id=64f08114&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f08114",
  null
  
)

export default component.exports