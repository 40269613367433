<template>
  <div class="reach">
    <div class="reach-area">
      <img class="title" :src="imgInfo.title_img" alt="" />
      <div
        class="people"
        :style="{
          'background-image': 'url(' + imgInfo.reach_bg + ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        }"
      >
        <span v-html="addCommas($t('reward.peopleNum'))"></span>
        <img
          src="@/assets/mobile/reach/punct.png"
          @click="showTipsModal"
          alt=""
        />
      </div>
      <div class="area">
        <div
          class="area_item"
          v-for="(item, index) in imgInfo.reward_list"
          :key="index"
          :style="{
            'background-image': 'url(' + item.img + ')',
            'background-size': '100% 100%',
            'background-repeat': 'no-repeat',
            'background-position': 'center center',
          }"
        >
          <div
            class="tag"
            :class="{ end_tag: index === 4 }"
            v-if="item.reach"
            :style="{
              'background-image': 'url(' + imgInfo.reach_img + ')',
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
import { mapMutations, mapState } from "vuex";

export default {
  name: "component_name",
  data() {
    return {
      activePeopleNum: 0,
      peopleNumberObj: {
        0: { total: 8640, min: 3, max: 5 },
        1: { total: 18360, min: 3, max: 6 },
        2: { total: 31320, min: 5, max: 7 },
        3: { total: 45360, min: 5, max: 8 },
        4: { total: 61560, min: 7, max: 8 },
        5: { total: 77760, min: 6, max: 9 },
        6: { total: 95040, min: 7, max: 9 },
        7: { total: 112320, min: 6, max: 10 },
        8: { total: 130680, min: 7, max: 10 },
        9: { total: 150120, min: 8, max: 10 },
        10: { total: 172800, min: 9, max: 12 },
        11: { total: 193320, min: 8, max: 11 },
        12: { total: 212760, min: 8, max: 10 },
        13: { total: 230040, min: 7, max: 9 },
        14: { total: 246240, min: 7, max: 8 },
        15: { total: 262440, min: 6, max: 9 },
        16: { total: 278640, min: 7, max: 8 },
        17: { total: 293760, min: 6, max: 8 },
        18: { total: 309960, min: 6, max: 9 },
        19: { total: 324000, min: 5, max: 8 },
        20: { total: 338040, min: 6, max: 7 },
        21: { total: 351000, min: 5, max: 7 },
        22: { total: 365040, min: 5, max: 8 },
        23: { total: 376920, min: 5, max: 6 },
        24: { total: 388800, min: 4, max: 7 },
        25: { total: 399600, min: 4, max: 6 },
        26: { total: 409320, min: 4, max: 5 },
        27: { total: 420120, min: 4, max: 6 },
        28: { total: 432000, min: 4, max: 7 },
        29: { total: 440640, min: 3, max: 5 },
        30: { total: 448200, min: 2, max: 5 },
        31: { total: 455760, min: 3, max: 4 },
        32: { total: 461160, min: 2, max: 3 },
        33: { total: 467640, min: 2, max: 4 },
        34: { total: 473040, min: 2, max: 3 },
        35: { total: 479520, min: 2, max: 4 },
        36: { total: 484920, min: 2, max: 3 },
        37: { total: 490320, min: 1, max: 4 },
        38: { total: 494640, min: 1, max: 3 },
        39: { total: 500040, min: 1, max: 4 },
        40: { total: 504360, min: 1, max: 3 },
        41: { total: 508680, min: 1, max: 3 },
        42: { total: 511920, min: 1, max: 2 },
        43: { total: 517320, min: 2, max: 3 },
        44: { total: 521640, min: 1, max: 3 },
        45: { total: 524880, min: 1, max: 2 },
      },
    };
  },
  watch: {
    activePeopleNum: {
      handler(val) {
        if (val) {
          console.log("当前预约人数=>", val);
        }
      },
    },
  },
  created() {},
  computed: {
    ...mapState(["msg", "title", "region", "swiperEnable"]),
    imgInfo() {
      const imgObj = {
        TW: {
          title_img: require("../../assets/mobile/reach/title.png"),
          reach_bg: require("../../assets/mobile/reach/bp1.png"),
          reward_list: [
            {
              reach: this.activePeopleNum >= 100000,
              img: require("@/assets/mobile/reach/bpgift1.png"),
            },
            {
              reach: this.activePeopleNum >= 200000,
              img: require("@/assets/mobile/reach/bpgift2.png"),
            },
            {
              reach: this.activePeopleNum >= 300000,
              img: require("@/assets/mobile/reach/bpgift3.png"),
            },
            {
              reach: this.activePeopleNum >= 400000,
              img: require("@/assets/mobile/reach/bpgift4.png"),
            },
            {
              reach: this.activePeopleNum >= 500000,
              img: require("@/assets/mobile/reach/bpgift5.png"),
            },
          ],
          reach_img: require("@/assets/mobile/reach/dc.png"),
        },
      };
      return imgObj[this.region];
    },

    homeInfo() {
      return {
        bg: require("@/assets/pc/gift/bg.png"),
        name: "home",
      };
    },
    addCommas() {
      return (text) => {
        let str = text.replaceAll(
          "X",
          `<span style="
            font-size: 1.05rem;
            height: 1.05rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFEA8A;
            margin:0rem 0.2rem
            text-decoration:none
            "
            >
            ${this.activePeopleNum}
            </span>`
        );
        return str;
      };
    },
    giftList() {
      return [
        {
          bg: require("@/assets/pc/gift/award_5.png"),
          introduce: require("@/assets/pc/gift/introduce_5.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_10.png"),
          introduce: require("@/assets/pc/gift/introduce_10.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_20.png"),
          introduce: require("@/assets/pc/gift/introduce_20.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_35.png"),
          introduce: require("@/assets/pc/gift/introduce_35.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_50.png"),
          introduce: require("@/assets/pc/gift/introduce_50.png"),
        },
      ];
    },
  },
  mounted() {
    let today = new Date();
    // 对当前系统时间的偏移
    let offset = today.getTimezoneOffset() * 60000;
    let utcTime = today.getTime() + offset + 8 * 60 * 60000; // 台湾区 = utc+8h
    let curTime = new Date(utcTime).getTime();

    // 结束时间的utc时间，转为本地时区时间，在计算对应utc时间戳
    let endLocalTime = convertUTCTimeToLocalTime("2024-07-18T24:00:00.000Z");
    let endUtcTime =
      new Date(endLocalTime).getTime() +
      new Date(endLocalTime).getTimezoneOffset() * 60000 +
      8 * 60 * 60000;
    let endTime = new Date(endUtcTime).getTime();

    let curDay = 46 - Math.round((endTime - curTime) / (24 * 60 * 60000));

    let passTime = Math.ceil(
      (new Date(utcTime).getHours() * 60 * 60 * 1000 +
        new Date(utcTime).getMinutes() * 60 * 1000 +
        new Date(utcTime).getSeconds() * 1000 +
        new Date(utcTime).getMilliseconds()) /
        80000
    );

    if (curDay >= 46) {
      this.timer = null;
      this.activePeopleNum = this.peopleNumberObj[45].total;
      return;
    } else if (curDay < 0) {
      this.timer = null;
      this.activePeopleNum = 0;
      return;
    }
    let { max, min } = this.peopleNumberObj[curDay];
    // 刷新，首次进入按照当天已过秒数根据增长曲线计算已有人数
    let hasGetTodayPeople = Math.round(passTime * (max + min));
    this.activePeopleNum =
      curDay == 0
        ? hasGetTodayPeople
        : this.peopleNumberObj[curDay - 1].total + hasGetTodayPeople;

    // 每日最后40s人数自动赋值当天目标最大值
    // 当天的23:59:49
    var todayEndFirstTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86320000
    ).getTime(); //毫秒
    // 第二天的00:00:00
    var todayEndLastTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86400000
    ).getTime(); //毫秒

    if (
      new Date(utcTime).getTime() >= todayEndFirstTime &&
      new Date(utcTime).getTime() <= todayEndLastTime
    ) {
      this.activePeopleNum = this.peopleNumberObj[curDay - 1].total;
    }
  },

  methods: {
    isInRange(value, min, max) {
      return value >= min && value < max;
    },
    showTipsModal() {
      this.$tipMobileModal({
        type: "type2",
        onClose: () => {
          console.log("对话框已关闭");
        },
      });
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.reach {
  width: 100%;
  height: 78rem;
  margin-top: 20rem;
  position: relative;
  &-area {
    width: calc(100% - 5.3rem);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 2.65rem;
    top: 0%;
    z-index: 999;
    .title {
      width: 30.8rem;
    }
    .people {
      width: 28.17rem;
      height: 3.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 1.2rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        height: 1.2rem;
        line-height: 1.2rem;
        letter-spacing: 0.1rem;
      }
      img {
        width: 2rem;
        height: 2rem;
        position: relative;
        z-index: 999;
      }
    }
    .area {
      width: 100%;
      height: 45%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      &_item {
        width: calc((100% - 1.45rem) / 2);
        height: 20rem;
        transform: scale(1.2);
        position: relative;
        // margin-bottom: -2.5rem;
        &:last-child {
          width: 108%;
          height: 34rem;
          position: absolute;
          left: 50%;
          bottom: -95%;
          transform: translate(-50%, 0);
        }
        .tag {
          width: 6rem;
          height: 6rem;
          position: absolute;
          bottom: 14%;
          right: 9%;
        }
        .end_tag {
          width: 6rem;
          height: 6rem;
          position: absolute;
          z-index: 99;
          bottom: 13%;
          right: 5%;
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>
