export const getLanguageInfo = () => {
  const lan = localStorage.getItem("lang") || navigator.s || navigator.language;
  const lowerLan = lan.toLowerCase();
  let locale = "";
  let lang = "";
  if (lowerLan.includes("tw")) {
    locale = "zh-TW";
    lang = "TW";
  } else if (lowerLan.includes("ko")) {
    locale = "ko";
    lang = "KO";
  } else {
    locale = "ja";
    lang = "TW";
  }
  return { locale, lang };
};
