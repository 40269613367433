import twLocale from "element-ui/lib/locale/lang/zh-TW";

const tw = {
  msg: {
    title: "提示",
    confirm: "確定",
    cancel: "取消",
    loading: "請等待...",
    login: "請登錄",
    logout: "登出",
    welcome: "歡迎!",
    copy_success: "複製成功",
    copy_fail: "複製失败",
    email_regis_done: "預約專享福利",
    login_tips: "請預約",
  },
  register: {
    title1: "電話號碼預約送好禮",
    tip: "-請輸入您的電話號碼-",
    agree: "同意個人資料的收集使用及接收獎勵諮詢",
    agree1: "服務條款",
    agree2: "隱私協議",
    regis: "預約註冊",
    regisResult: "報酬の表示",
    title2: "商店預約送",
    tip1: "您的獎勵序列號為：",
    tip2: "前往（任意）媒體點選關注官方賬號可獲得1次抽獎次數；",
    tip3: "你可以在商店預約並獲得更多的豪華報酬",
    tip4: "*遊戲上線後可以領取",
  },
  reward: {
    peopleNum: "已有 X 人完成預約",
  },
  errMsg: {
    $10000: "服務器內部錯誤",
    $10001: "當前設備已預約",
    $10002: "抽獎次數不足",
    $10003: "當前電話號碼已被預約",
    $10004: "當前IP已超過預約上線",
    $10005: "邀請人不存在",
  },
  prize: {
    $1: "分享專屬鏈接：",
    $2: "複製你的專屬連結",
    $3: "複製",
    $4: "序號",
    $5: "獎勵內容",
    $6: "中獎時間",
    $7: "中獎號碼",
    $8: "備註：中獎時間均為北京時間（UTC+8）",
    $9: "遊戲上線後可以領取",
    $10: "最多可獲得5次抽獎次數",
    $11: "當前已獲得次數：",
    $12: "每完成一項任務，可獲得1次抽獎次數：",
  },
  role1: {
    // 腕龙
    name: "腕龍",
    level: "橙色",
    type: "發展",
    tag: ["草食召集", "肉食召集", "雜食召集"],
    intro:
      "生存於侏羅紀晚期，是蜥腳類恐龍的一個屬。種名意為“寬闊的胸腔”，曾被認為是世界上體型最大的恐龍之一，也是世界上最著名的大型植食性恐龍。",
  },
  role2: {
    // 牛龙
    name: "牛龍",
    level: "橙色",
    type: "戰鬥",
    tag: ["全能增益", "疾行", "速戰速決"],
    intro:
      "名食肉牛龍，通常簡稱為牛龍，生存於白堊紀晚期，屬名意為“食肉的牛”，是行動最迅速的大型獸腳類恐龍之一。",
  },
  role3: {
    // 棘龙
    name: "棘龍",
    level: "橙色",
    type: "戰鬥",
    tag: ["雜食增益", "疾行", "速戰速決"],
    intro:
      "又名棘背龍、脊背龍，生存於白堊紀晚期，屬名意為“有棘的蜥蜴”，是目前已知最大型的陸生肉食性恐龍之一、也是長度最長的肉食性恐龍。",
  },
  role4: {
    // 甲龙
    name: "甲龍",
    level: "橙色",
    type: "戰鬥",
    tag: ["草食增益", "疾行", "速戰速決"],
    intro:
      "生存於白堊紀晚期，屬名意為“僵硬的蜥蜴”，是甲龍科恐龍的一個屬，最大的特點是擁有全骨板覆蓋的身驅及巨型的尾部棒槌，體重2噸到4噸，身高比同體長的恐龍矮很多，方便重心下移、用甲殼保護自己柔軟的腹部。",
  },
  role5: {
    // 霸王龙
    name: "霸王龍",
    level: "橙色",
    type: "戰鬥",
    tag: ["肉食增益", "疾行", "速戰速決"],
    intro:
      "即暴龍，生存於白堊紀晚期，暴龍屬下唯一種——君王暴龍，屬名意為“暴君蜥蜴”，是最具代表性的大型獸腳類恐龍之一，是世界上身高最高同時體重最大的肉食恐龍，也是恐龍中最為著名的頂級掠食者。",
  },

  // 异兽
  role6: {
    // 野马
    name: "野馬",
    level: "橙色",
    type: "戰鬥",
    tag: ["傷害", "沉默", "減傷"],
    intro:
      "馬科，馬屬動物。世界各地均有分佈，根據地區不同產生多種類型的分化。體格結實健壯，四肢修長勻稱，性格強悍。喜歡羣居，一般由一匹公馬，幾匹母馬和它們的後代組成。每個種羣都有明確的活動範圍，並以每天5-10公里的速度遷移。",
  },
  role7: {
    // 小熊猫
    name: "小熊貓",
    level: "紫色",
    type: "採集",
    tag: ["發展", "漿果收集"],
    intro:
      "小熊貓科，小熊貓屬動物。生活在緬甸北部、尼泊爾、印度錫金地區以及中國四川西部和雲南地區海拔2200至4800米的喜馬拉雅山脈中。棲居於大的樹洞或石洞和岩石縫中。常常早晚出來活動覓食，白天多在洞裡或大樹的蔭涼處睡覺。",
  },
  role8: {
    // 懒猴
    name: "懶猴",
    level: "橙色",
    type: "採集",
    tag: ["發展", "蜂蜜收集"],
    intro:
      "懶猴科，蜂猴屬動物。分佈於東南亞及周邊地區，是雜食性動物，食物包括熱帶鮮嫩的花葉和漿果，食蟲和其他節肢動物，小鳥和爬行動物，卵、水果、樹膠、花蜜和其他植被。通常整夜都在獨自覓食。善於在夜間捕食熟睡的小鳥，喜食鳥蛋。也喜食蜂蜜，因此名“蜂猴”。",
  },
  role9: {
    // 西伯利亚虎
    name: "西伯利亞虎",
    level: "橙色",
    type: "戰鬥",
    tag: ["傷害", "單點突破"],
    intro:
      "貓科，豹屬動物。分佈於俄羅斯遠東和中國東北的吉林、黑龍江地區。是現存體重最大的貓科動物，世界上最頂級的掠食者之一，被譽為“萬獸之王”。是世界上著名的動物之一。",
  },
  role10: {
    // 白孟加拉虎
    name: "白孟加拉虎",
    level: "橙色",
    type: "戰鬥",
    tag: ["傷害", "壓制", "群體猛擊"],
    intro:
      "貓科，豹屬，簡稱“白虎”，世界一級保護動物，是印度國寶。由於基因突變導致孟加拉虎原本橙黃色底黑色條紋的毛髮轉變成白底黑紋，是孟加拉虎的一個變種，並非人們常以為的白化病。",
  },
  role11: {
    // 草原狮
    name: "草原獅",
    level: "橙色",
    type: "戰鬥",
    tag: ["傷害", "群體猛擊"],
    intro:
      "貓科，豹屬動物。分佈於非洲的草原地區，亞洲印度也有分佈，是現存平均體重最大的貓科動物，也是在世界上唯一一種雌雄兩態的貓科動物。非洲大陸上最頂級的掠食者，被稱為“草原之王”。",
  },
  ...twLocale,
};
export default tw;
