<template>
  <div class="footer">
    <div class="footer-content">
      <div class="logo_cls" style="margin-right: 1%">
        <img
          src="@/assets/logo_1.png"
          style="
            width: 4rem;
            height: 4rem;
            margin-right: 1rem;
            margin-left: 0.7rem;
          "
          alt="logo"
        />
        <div>
          <p>Copyright © 2022~2025 版權所有</p>
          <p :style="{ marginTop: 0 }">
            <a
              href="https://static-sites.allstarunion.com/service/general_privacy_tw.html"
              target="_blank"
              >{{ $t("register.agree2") }}</a
            >
            ｜
            <a
              href="https://static-sites.allstarunion.com/service/general_protocol_tw.html"
              target="_blank"
              >{{ $t("register.agree1") }}</a
            >
            <!-- ｜
              <span class="cur_pointer" @click="connUs">聯絡我們</span> -->
          </p>
        </div>
      </div>
      <div class="logo_cls">
        <img
          src="@/assets/logo_2.png"
          style="width: 5.5rem; height: 5.5rem; margin-right: -0.667rem"
          alt="logo"
        />
        <div style="margin: 0.5rem 0 0 0.267rem">
          <p>※ 本軟體因涉及暴力，依遊戲軟體分級管理辦法分類為輔 12 級。</p>
          <p>
            ※ 本遊戲為免費使用，遊戲內另提供購買虛擬遊戲幣、物品等付費服務。
          </p>
          <p>※ 長時間進行遊戲，請注意使用時間，避免沉迷於遊戲。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      protocolAddress: {
        //隐私协议
        privacy: {
          ZH: "https://static-sites.allstarunion.com/service/general_privacy_cn.html",
          TW: "https://static-sites.allstarunion.com/service/general_privacy_tw.html",
          EN: "https://static-sites.allstarunion.com/service/general_privacy_en.html",
          KO: "https://static-sites.allstarunion.com/service/general_privacy_ko.html",
        },
        // 服务条款
        service: {
          ZH: "https://static-sites.allstarunion.com/service/general_protocol_cn.html",
          TW: "https://static-sites.allstarunion.com/service/general_protocol_tw.html",
          EN: "https://static-sites.allstarunion.com/service/general_protocol_en.html",
          KO: "https://static-sites.allstarunion.com/service/general_protocol_ko.html",
        },
      },
    };
  },
  computed: {
    ...mapState(["mobile", "language"]),
    /**
     * @description: 移动端对log图片缩小处理
     * @param {}
     * @return 返回类名
     */
    countImgSize() {
      return "mobile-img";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 16.78rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(11, 9, 0, 0.58);
  position: absolute;
  bottom: 0;
  z-index: 999999;
  left: 0;
  &-content {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .logo_cls {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        text-align: left;
        color: #fff;
        font-size: 1.7rem;

        p {
          margin: 0.133rem 0.133rem 0 0.1333rem;

          a {
            color: #fff;

            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
